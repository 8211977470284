import { Box,Button,useTheme } from "@mui/material";
import { DataGrid, esES, GridToolbar } from "@mui/x-data-grid";
import useAuth from "../../auth/useAuth";
import Header from "../../components/Header"
import url from "../../config";
import { useFetch2 } from "../../state/useFetch";
import { tokens } from "../../theme";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useLocation, useNavigate } from "react-router-dom";
import Loading from "../global/Loading";
import FatalError from "../global/FatalError";

const Piscinas = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode)
  const auth = useAuth();
  const token = auth.globals.token

  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const _user = query.get('_user')

  const {data,loading,error} = useFetch2(_user!=null?`${url}/api/pools/user/${_user}`:`${url}/api/pools`,token)

  const navigate = useNavigate();

  const columns=[
    {
      field:"_id", 
      headerName: "ID",
      hide: true,
      hideable : false,
    },
    {
      field:"user_email", 
      headerName: "Usuario", 
      flex: 1, 
      cellClassName: "user_email-column--cell"
    },
    {
      field:"poolname", 
      headerName: "Nombre Piscina",
      flex: 1, 
      cellClassName: "poolname-column--cell"
    },
    {
      field:"country", 
      headerName: "País",
      flex: 1, 
      cellClassName: "country-column--cell"
    },
    {
      field:"dep", 
      headerName: "Departamento",
      flex: 1, 
      cellClassName: "dep-column--cell"
    },
    {
      field:"city", 
      headerName: "Ciudad",
      flex: 1, 
      cellClassName: "city-column--cell"
    },
    {
      field:"gallonage", 
      headerName: "Galones",
      flex: 1
    },
    {
      field:"status", 
      headerName: "Estado",
      flex: 1, 
      cellClassName: "status-column--cell"
    },
    {
      field:"createdAt", 
      headerName: "Fecha Creación",
      flex: 1, 
      cellClassName: "createdAt-column--cell",
      hide: true,
      type: 'dateTime',
      valueGetter: ({ value }) => value && new Date(value)
    },
    {
      field:"updatedAt", 
      headerName: "Fecha Modificacion",
      flex: 1, 
      cellClassName: "updatedAt-column--cell",
      hide: true,
      type: 'dateTime',
      valueGetter: ({ value }) => value && new Date(value)
    },
    {
      field:"actions", 
      headerName: "Ver",
      flex: 1, 
      cellClassName: "actions-column--cell",
      renderCell:({ row: { _id } })=>{
        return(
          <Box
            width="80%"
            maxWidth="175px"
            height="75%"
            maxHeight="35px" 
            display="flex" 
            justifyContent="center" 
            m="0 auto">
            <Button
              color="secondary"
              variant="contained"
              onClick={()=>{navigate("/Piscinas/Form?_id="+_id)}}>
            <VisibilityIcon/>
              <Box sx={{ml:"5px"}}>
                Ver
              </Box>
            </Button>
          </Box>
        )
      }
    }
  ]

  if(loading)
  return (<Loading/>)

  if(error)
  return(<FatalError/>)

  return (
    <Box m="20px">
      {/*<Box display="flex" justifyContent="space-between" alignItems="center">*/}
        <Header title={_user!=null?"":"PISCINAS"} subtitle="Listado de Piscinas"/>
        <Box m="20px 0 0 0" height="67vh" sx={{
          "& .MuiDataGrid-root":{
            border: "none",
          },
          "& .MuiDataGrid-cell":{
            borderBottom: "none"
          },
          "& .user_email-column--cell":{
            color: colors.greenAccent[300]
          },
          "& .MuiDataGrid-columnHeaders":{
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
            color: "white"
          },
          "& .MuiDataGrid-virtualScroller":{
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer":{
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text":{
            color: `${colors.grey[100]} !important`,
          },
          "& .MuiToolbar-gutters":{
            color: "white"
          }

        }}>
          <DataGrid
            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
            rows={data}
            columns={columns}
            getRowId={(row) => row._id}
            components={{Toolbar: GridToolbar}}
          />
        </Box>
    </Box>
  );


}

export default Piscinas;