import { Box, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import Header from "../../components/Header"
import React, { useEffect, useState } from 'react';
import useAuth from "../../auth/useAuth";
import { useFetch2, useFetch3, useFetch4, useFetch5, useFetch6, useFetch7, useFetch8, useFetch9 } from "../../state/useFetch";
import url from "../../config";
import BarChart from "../../components/BarChart";
import { tokens } from "../../theme";
import GeoMap from "../../components/GeoMap";
import StatBox from "../../components/StatBox";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import PoolOutlinedIcon from "@mui/icons-material/PoolOutlined";
import GradingIcon from '@mui/icons-material/Grading';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import { Formik } from "formik";
import Loading from "../global/Loading";
import FatalError from "../global/FatalError";
import PieChart from "../../components/PieChart";

const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode)
  const auth = useAuth();
  const token = auth.globals.token
  const {data,loading,error} = useFetch2(`${url}/api/pools`,token)
  const {data3,loading3,error3} = useFetch3(`${url}/api/clients`,token)
  const [dataFiltrada, setDataFiltrada] = useState(data)
  const [data3Filtrada, setData3Filtrada] = useState(data3)
  const {data4,loading4,error4} = useFetch4(`${url}/api/clients/statistics`,token)
  const {data5,loading5,error5} = useFetch5(`${url}/api/pools/statistics`,token)
  const {data6,loading6,error6} = useFetch6(`${url}/api/reviews/statistics`,token)
  const {data7,loading7,error7} = useFetch7(`${url}/api/applications/statistics`,token)
  const {data8,loading8,error8} = useFetch8(`${url}/api/applications`,token)
  const {data9,loading9,error9} = useFetch9(`${url}/api/reviews`,token)
  const [dataPieFiltrada, setDataPieFiltrada] = useState([
    {
      "id": "Revisiones",
      "label": "Revisiones",
      "value": 0,
    },
    {
      "id": "Aplicaciones",
      "label": "Aplicaciones",
      "value": 0,
    },
  ])
  const isNonMobile = useMediaQuery("(min-width:600px)")

  const [initialValues,setInicitalValues] = useState({
    init_date: "",
    final_date: ""
  })


  useEffect(()=>{
    let nuevaDataPiscinas = []
    let nuevaDataClientes = []
    if (initialValues.init_date === "" && initialValues.final_date === ""){
      setDataFiltrada(data);
      setData3Filtrada(data3);
      let contadorAplicaciones = data8?.length;
      let contadorRevisiones = data9?.length;
      setDataPieFiltrada([
        {
          "id": "Revisiones",
          "label": "Revisiones",
          "value": contadorRevisiones,
        },
        {
          "id": "Aplicaciones",
          "label": "Aplicaciones",
          "value": contadorAplicaciones,
        },
      ])
    }else if (initialValues.init_date !=="" && initialValues.final_date !== ""){
      data.forEach(function(piscina) {
        let fechaCreada = piscina.createdAt.slice(0,10)
        let fechaSplit = fechaCreada.split("-")
        let anio = parseInt(fechaSplit[0])
        let mes = parseInt(fechaSplit[1])
        let dia = parseInt(fechaSplit[2])
        let fechaPiscina = new Date(anio,mes,dia)
        if ((fechaPiscina>=initialValues.init_date)&&(fechaPiscina<=initialValues.final_date)){
          nuevaDataPiscinas.push(piscina)
          //setDataFiltrada(nuevaDataPiscinas);
        }
      });
      setDataFiltrada(nuevaDataPiscinas);
      data3.forEach(function(cliente) {
        let fechaCreado = cliente.createdAt.slice(0,10)
        let fechaSplit = fechaCreado.split("-")
        let anio = parseInt(fechaSplit[0])
        let mes = parseInt(fechaSplit[1])
        let dia = parseInt(fechaSplit[2])
        let fechaCliente = new Date(anio,mes,dia)
        if ((fechaCliente>=initialValues.init_date)&&(fechaCliente<=initialValues.final_date)){
          nuevaDataClientes.push(cliente)
          //setData3Filtrada(nuevaDataClientes);
        }
      });
      setData3Filtrada(nuevaDataClientes);
      let contadorAplicaciones = 0;
      let contadorRevisiones = 0;
      data8.forEach(function(aplicacion) {
        let fechaCreado = aplicacion.createdAt.slice(0,10)
        let fechaSplit = fechaCreado.split("-")
        let anio = parseInt(fechaSplit[0])
        let mes = parseInt(fechaSplit[1])
        let dia = parseInt(fechaSplit[2])
        let fechaAplicacion = new Date(anio,mes,dia)
        if ((fechaAplicacion>=initialValues.init_date)&&(fechaAplicacion<=initialValues.final_date)){
          contadorAplicaciones += 1;
        }
      });
      data9.forEach(function(revision) {
        let fechaCreado = revision.createdAt.slice(0,10)
        let fechaSplit = fechaCreado.split("-")
        let anio = parseInt(fechaSplit[0])
        let mes = parseInt(fechaSplit[1])
        let dia = parseInt(fechaSplit[2])
        let fechaRevision = new Date(anio,mes,dia)
        if ((fechaRevision>=initialValues.init_date)&&(fechaRevision<=initialValues.final_date)){
          contadorRevisiones += 1;
        }
      });
      setDataPieFiltrada([
        {
          "id": "Revisiones",
          "label": "Revisiones",
          "value": contadorRevisiones,
        },
        {
          "id": "Aplicaciones",
          "label": "Aplicaciones",
          "value": contadorAplicaciones,
        },
      ])
    }else if (initialValues.init_date !==""){
      data.forEach(function(piscina) {
        let fechaCreada = piscina.createdAt.slice(0,10)
        let fechaSplit = fechaCreada.split("-")
        let anio = parseInt(fechaSplit[0])
        let mes = parseInt(fechaSplit[1])
        let dia = parseInt(fechaSplit[2])
        let fechaPiscina = new Date(anio,mes,dia)
        if (fechaPiscina>=initialValues.init_date){
          nuevaDataPiscinas.push(piscina)
          //setDataFiltrada(nuevaDataPiscinas);
        }
      });
      setDataFiltrada(nuevaDataPiscinas);
      data3.forEach(function(cliente) {
        let fechaCreado = cliente.createdAt.slice(0,10)
        let fechaSplit = fechaCreado.split("-")
        let anio = parseInt(fechaSplit[0])
        let mes = parseInt(fechaSplit[1])
        let dia = parseInt(fechaSplit[2])
        let fechaCliente = new Date(anio,mes,dia)
        if (fechaCliente>=initialValues.init_date){
          nuevaDataClientes.push(cliente)
          //setData3Filtrada(nuevaDataClientes);
        }
      });
      setData3Filtrada(nuevaDataClientes);
      let contadorAplicaciones = 0;
      let contadorRevisiones = 0;
      data8.forEach(function(aplicacion) {
        let fechaCreado = aplicacion.createdAt.slice(0,10)
        let fechaSplit = fechaCreado.split("-")
        let anio = parseInt(fechaSplit[0])
        let mes = parseInt(fechaSplit[1])
        let dia = parseInt(fechaSplit[2])
        let fechaAplicacion = new Date(anio,mes,dia)
        if (fechaAplicacion>=initialValues.init_date){
          contadorAplicaciones += 1;
        }
      });
      data9.forEach(function(revision) {
        let fechaCreado = revision.createdAt.slice(0,10)
        let fechaSplit = fechaCreado.split("-")
        let anio = parseInt(fechaSplit[0])
        let mes = parseInt(fechaSplit[1])
        let dia = parseInt(fechaSplit[2])
        let fechaRevision = new Date(anio,mes,dia)
        if (fechaRevision>=initialValues.init_date){
          contadorRevisiones += 1;
        }
      });
      setDataPieFiltrada([
        {
          "id": "Revisiones",
          "label": "Revisiones",
          "value": contadorRevisiones,
        },
        {
          "id": "Aplicaciones",
          "label": "Aplicaciones",
          "value": contadorAplicaciones,
        },
      ])
    }else if (initialValues.final_date !==""){
      data.forEach(function(piscina) {
        let fechaCreada = piscina.createdAt.slice(0,10)
        let fechaSplit = fechaCreada.split("-")
        let anio = parseInt(fechaSplit[0])
        let mes = parseInt(fechaSplit[1])
        let dia = parseInt(fechaSplit[2])
        let fechaPiscina = new Date(anio,mes,dia)
        if (fechaPiscina<=initialValues.final_date){
          nuevaDataPiscinas.push(piscina)
          //setDataFiltrada(nuevaDataPiscinas);
        }
      });
      setDataFiltrada(nuevaDataPiscinas);
      data3.forEach(function(cliente) {
        let fechaCreado = cliente.createdAt.slice(0,10)
        let fechaSplit = fechaCreado.split("-")
        let anio = parseInt(fechaSplit[0])
        let mes = parseInt(fechaSplit[1])
        let dia = parseInt(fechaSplit[2])
        let fechaCliente = new Date(anio,mes,dia)
        if (fechaCliente<=initialValues.final_date){
          nuevaDataClientes.push(cliente)
          //setData3Filtrada(nuevaDataClientes);
        }
      });
      setData3Filtrada(nuevaDataClientes);
      let contadorAplicaciones = 0;
      let contadorRevisiones = 0;
      data8.forEach(function(aplicacion) {
        let fechaCreado = aplicacion.createdAt.slice(0,10)
        let fechaSplit = fechaCreado.split("-")
        let anio = parseInt(fechaSplit[0])
        let mes = parseInt(fechaSplit[1])
        let dia = parseInt(fechaSplit[2])
        let fechaAplicacion = new Date(anio,mes,dia)
        if (fechaAplicacion<=initialValues.final_date){
          contadorAplicaciones += 1;
        }
      });
      data9.forEach(function(revision) {
        let fechaCreado = revision.createdAt.slice(0,10)
        let fechaSplit = fechaCreado.split("-")
        let anio = parseInt(fechaSplit[0])
        let mes = parseInt(fechaSplit[1])
        let dia = parseInt(fechaSplit[2])
        let fechaRevision = new Date(anio,mes,dia)
        if (fechaRevision<=initialValues.final_date){
          contadorRevisiones += 1;
        }
      });
      setDataPieFiltrada([
        {
          "id": "Revisiones",
          "label": "Revisiones",
          "value": contadorRevisiones,
        },
        {
          "id": "Aplicaciones",
          "label": "Aplicaciones",
          "value": contadorAplicaciones,
        },
      ])
    }

  },[initialValues.init_date,initialValues.final_date,data,data3,data8,data9])


  const handleChange = (event) => {
    const date = event.target.value;
    let fechaSplit = date.split("-")
    let anio = parseInt(fechaSplit[0])
    let mes = parseInt(fechaSplit[1])
    let dia = parseInt(fechaSplit[2])
    if (anio>1990 && anio<3000){
      setInicitalValues({
            ...initialValues,
           [event.target.name]: new Date(anio,mes,dia)
      })
    } else{
      setInicitalValues({
        ...initialValues,
       [event.target.name]: ""
  })    
    }
  }

  const handleFormSubmit = (values) => {
      console.log('');
    }


  if(loading||loading3||loading4||loading5||loading6||loading7||loading8||loading9)
  return (<Loading/>)

  if(error||error3||error4||error5||error6||error7||error8||error9){
    return(<FatalError/>)
  }
  
  return (
    <Box m="15px" marginBottom="64px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="DASHBOARD" subtitle="Bienvenido"/>
      </Box>

      <Box
        display="grid"
        gridTemplateColumns="repeat(12,1fr)"
        gridAutoRows="17vh"
        gap="20px"
        paddingBottom="6px"
      >
        {/*FILA 1 */}
        <Box
          gridColumn="span 3" 
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={data4.total}
            subtitle1="Clientes"
            valor = {"+ "+data4.total_current_month+" Este mes"}
            subtitle2="Crecimiento"
            progress={data4.total===0?0:data4.total_current_month===0?0:data4.total_current_month/data4.total}
            increase={"+ "+(data4.total===0?0:data4.total===data4.total_current_month?100:(data4.total_current_month/(data4.total-data4.total_current_month))*100).toFixed(2)+"%"}
            icon={
              <PeopleOutlinedIcon 
                sx={{ color: colors.greenAccent[400], 
                fontSize:"26px"}}/>
            }
          />
        </Box>
        <Box
          gridColumn="span 3" 
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={data5.total}
            subtitle1="Piscinas"
            valor = {"+ "+data5.total_current_month+" Este mes"}
            subtitle2="Crecimiento"
            progress={data5.total===0?0:data5.total_current_month===0?0:data5.total_current_month/data5.total}
            increase={"+ "+(data5.total===0?0:data5.total===data5.total_current_month?100:(data5.total_current_month/(data5.total-data5.total_current_month))*100).toFixed(2)+"%"}
            icon={
              <PoolOutlinedIcon 
                sx={{ color: colors.greenAccent[400], 
                fontSize:"26px"}}/>
            }
          />
        </Box>
        <Box
          gridColumn="span 3" 
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={data7.total}
            subtitle1="Aplicaciones"
            valor = {"+ "+data7.total_current_month+" Este mes"}
            subtitle2="Crecimiento"
            progress={data7.total===0?0:data7.total_current_month===0?0:data7.total_current_month/data7.total}
            increase={"+"+(data7.total===0?0:data7.total===data7.total_current_month?100:(data7.total_current_month/(data7.total-data7.total_current_month))*100).toFixed(2)+"%"}
            icon={
              <AssignmentTurnedInIcon 
                sx={{ color: colors.greenAccent[400], 
                fontSize:"26px"}}/>
            }
          />
        </Box>
        <Box
          gridColumn="span 3" 
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={data6.total}
            subtitle1="Revisiones"
            valor = {"+ "+data6.total_current_month+" Este mes"}
            subtitle2="Crecimiento"
            progress={data6.total===0?0:data6.total_current_month===0?0:data6.total_current_month/data6.total}
            increase={"+"+(data6.total===0?0:data6.total===data6.total_current_month?100:(data6.total_current_month/(data6.total-data6.total_current_month))*100).toFixed(2)+"%"}
            icon={
              <GradingIcon 
                sx={{ color: colors.greenAccent[400], 
                fontSize:"26px"}}/>
            }
          />
        </Box>        
      </Box>

      <Formik
        //enableReinitialize={true}
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        //onChange={handleChange}
        //validationSchema = {filtroSchema}
      >
        {({
          values, 
          errors, 
          touched, 
          handleBlur, 
          //handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
              <Box 
                m="7px"
                display="grid" 
                gap="30px" 
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": { gridColumn: isNonMobile? undefined : "span 4"}
                }}
              >               
                <TextField
                  fullWidth
                  variant="outlined"
                  type="date"
                  label="Inicio"
                  onBlur = {handleBlur}
                  onChange = {handleChange}
                  name = "init_date"
                  error = {!!touched.init_date && Boolean(errors.init_date)}
                  helperText={touched.init_date && errors.init_date}
                  sx={{ gridColumn: "span 1"}}
                  size="small"
                  focused
                  color="secondary"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    max: "9999-12-31", // Establecemos la longitud máxima a 10 (yyyy-mm-dd)
                  }}
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  type="date"
                  label="Fin"
                  onBlur = {handleBlur}
                  onChange = {handleChange}
                  name = "final_date"
                  error = {!!touched.final_date && Boolean(errors.final_date)}
                  helperText={touched.final_date && errors.final_date}
                  sx={{ gridColumn: "span 1"}}
                  size="small"
                  focused
                  color="secondary"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    max: "9999-12-31", // Establecemos la longitud máxima a 10 (yyyy-mm-dd)
                  }}
                />              
              </Box>               
          </form>
        )}
      </Formik>

    {/*FILA 2 */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12,1fr)"
        gridAutoRows="46vh"
        gap="15px"
      >
        <Box 
          p={1}
          gridColumn= {isNonMobile? "span 12" : "span 12"}
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="flex-start"
          justifyContent="flex-start"
          flexDirection="column"
        >
          <Typography
            padding="3px"
            variant="h5"
            fontWeight="600"
            color={colors.greenAccent[400]}
          >
            Mapa
          </Typography>
          <Box
          height="100%"
          width="100%"
          border={`1px solid ${colors.grey[100]}`}
          borderRadius="4px"
          >
            <GeoMap isDashboard={true} data={dataFiltrada} />
          </Box>
        </Box>        
        
        <Box 
          p={1}
          gridColumn={isNonMobile? "span 7" : "span 12"}
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="flex-start"
          justifyContent="flex-start"
          flexDirection="column"
        >
          <Typography
            padding="3px"
            variant="h5"
            fontWeight="600"
            color={colors.greenAccent[400]}
          >
            Piscinas y Clientes por País
          </Typography>
          <BarChart data={dataFiltrada} data3={data3Filtrada} />
        </Box>

        <Box 
          p={1}
          gridColumn={isNonMobile? "span 5" : "span 12"}
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="flex-start"
          justifyContent="flex-start"
          flexDirection="column"
        >
          <Typography
            padding="3px"
            variant="h5"
            fontWeight="600"
            color={colors.greenAccent[400]}
          >
            Revisiones vs. Aplicaciones
          </Typography>
          <PieChart data={dataPieFiltrada} />
        </Box>



      </Box>
    </Box>

  )
}

export default Dashboard;