import { Box, TextField,Button, MenuItem} from "@mui/material"
import { Formik } from "formik"
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { /*useEffect,*/ useState } from "react";
import { useNavigate } from "react-router-dom";
import swal from 'sweetalert';
//import { useLocation } from "react-router-dom";
import Header from "../../components/Header";
import useAuth from "../../auth/useAuth";
//import { useFetch2 } from "../../state/useFetch";
import url from "../../config";
import { departamentos, municipios, paises } from "../../data/ubicaciones";
import Loading from "../global/Loading";
import { format, parseISO } from 'date-fns';

const tipos = [
  {
    value: "a",
    label:"Emergencia/alerta"
  },
  {
    value: "b",
    label: "Comunicación comercial" 
  },
  {
    value: "c",
    label: "Notificación de app/uso"
  },
  { 
    value: "d",
    label: "Notificación de productos/piscinas"
  }
]

const dispositivos = [
  {
    value:"Android",
    label:"Android"
  },
  {
    value: "Iphone",
    label: "Iphone" 
  },
  {
    value: "Todos",
    label: "Todos"
  }
]


const incluir_empleados = [
  {
    value:"true",
    label:"Si"
  },
  {
    value: "false",
    label: "No" 
  }
]

const todas_las_ubicaciones = [
  {
    value:"true",
    label:"Si"
  },
  {
    value: "false",
    label: "No" 
  }
]
//const [fechaHoraActual,setFechaHoraActual] = useState(new Date())
//const [minFechaHora,setMinFechaHora] = useState(format(new Date(), "yyyy-MM-dd'T'HH:mm"))

const FormAlerta = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const token = auth.globals.token
  const isNonMobile = useMediaQuery("(min-width:600px)")
  const [ loadingData, setLoadingData ] = useState(false)
  const [ error2, setError2 ] = useState(null)
  const [minFechaHora,setMinFechaHora] = useState(format(new Date(), "yyyy-MM-dd'T'HH:mm"))

  const mostrarAlerta=(error)=>{
    if(error!=null){
      try{
        swal({
          title:"Error al programar Alerta",
          text: "Resultado: "+error,
          icon: "error",
          button:"Aceptar"
        })
        if(error==="No tiene autorización o su sesión ha expirado"){
          auth.logout();
        }
      }catch(e){
        swal({
          title:"Error al programar Alerta",
          text: "Error inesperado ",
          icon: "error",
          button:"Aceptar"
        })
      }
    }
    setError2(null)
  }

  const alertSucess=(text_title,text_content='')=>{
    
    try{
      swal({
        title:text_title,
        text: text_content,
        icon: "success",
        button:"Aceptar"
      })
    }catch(e){
      swal({
        title:"Error inesperado",
        text: "Error inesperado ",
        icon: "error",
        button:"Aceptar"
      })
    }
    navigate("/Dashboard")
  }

  const asyncsend2 = async(body) =>{
    body.send_date = format(parseISO(body.send_date),'dd/MM/yyyy-HH:mm')
    console.log('Enviar Alerta')
    try{
      
      let res = await fetch(url+"/api/campaings", {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          "x-access-token": token,
        },
        body: JSON.stringify(body),
        cors: 'no-cors'
      })
      let data = await res.json()
      if(res.ok){
        setLoadingData(false)
        alertSucess("Nueva Campaña",data.message)
        navigate("/Dashboard")       
      }else{
        body.send_date = ""
        setLoadingData(false)
        if(res.status===401){
          setError2("No tiene autorización o su sesión ha expirado")
        }else{
          setError2(data.message)
        }       
      }return data;
    }catch(error2){
      body.send_date = ""
      setLoadingData(false)
      setError2(error2)
    }
  }


  const [initialValues,setInicitalValues] = useState({
    notification_type: "",
    device_type: "",
    notification_title: "",
    body: "",
    send_date: "",
    department: "",
    city: "",
    country: "",
    location_all: "true",
    include_employees: "true"
  })



  const userSchema = yup.object().shape({
    notification_type: yup.string().required("* Valor Requerido"),
    device_type: yup.string().required("* Valor Requerido"),
    notification_title: yup.string().required("* Valor Requerido"),
    body: yup.string().required("* Valor Requerido"),
    send_date: yup.date().required("* Valor Requerido"),
    location_all: yup.string().required("* Valor Requerido"),
    include_employees: yup.string().required("* Valor Requerido"),
  })



  const handleFormSubmit = (values) => {
    //values.send_date = format(parseISO(values.send_date),'dd/MM/yyyy-HH:mm')
    setMinFechaHora(format(new Date(), "yyyy-MM-dd'T'HH:mm"))
    setInicitalValues(values)
    setLoadingData(true)
    asyncsend2(values);
    console.log(values)
  }

  const handleChangeUbicacion = (values,target) =>{
    values[target.name]=target.value;
    if(target.name==="location_all" && target.value==="true"){
      values.country = ""
      values.city = ""
      values.department = ""
    }
  }

  if(error2)
  return(mostrarAlerta(error2))
  else
  if(loadingData)
  return (<Loading/>)

  else
  return (
    <Box m="20px" marginBottom="64px">
      <Header title="Alertas" subtitle="Programar Nueva Alerta"/>    


      <Formik
        enableReinitialize={true}
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema = {userSchema}
      >
        {({
          values, 
          errors, 
          touched, 
          handleBlur, 
          handleChange,
          handleSubmit,
          setFieldValue
        }) => (
          <form onSubmit={handleSubmit}>
              <Box 
                display="grid" 
                gap="30px" 
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": { gridColumn: isNonMobile? undefined : "span 3"}
                }}
              >               
                <TextField
                  fullWidth
                  variant="filled"
                  label="Tipo de alerta"
                  select
                  onBlur = {handleBlur}
                  onChange = {(e) => {
                    handleChangeUbicacion(values,e.target);
                    setFieldValue("notification_type", e.target.value);
                  }}
                  value = {values.notification_type}
                  name = "notification_type"
                  sx={{ gridColumn: "span 1"}}
                  error = {!!touched.notification_type && !!errors.notification_type}
                  helperText={touched.notification_type && errors.notification_type}
                >
                  {tipos.map((tipo) => (
                    <MenuItem key={tipo.value} value={tipo.value}>
                      {tipo.label}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  fullWidth
                  variant="filled"
                  label="Tipo de dispositivo"
                  select
                  onBlur = {handleBlur}
                  onChange = {(e) => {
                    handleChangeUbicacion(values,e.target);
                    setFieldValue("device_type", e.target.value);
                  }}
                  value = {values.device_type}
                  name = "device_type"
                  sx={{ gridColumn: "span 1"}}
                  error = {!!touched.device_type && !!errors.device_type}
                  helperText={touched.device_type && errors.device_type}
                >
                  {dispositivos.map((dispositivo) => (
                    <MenuItem key={dispositivo.value} value={dispositivo.value}>
                      {dispositivo.label}
                    </MenuItem>
                  ))}
                </TextField>
                                
                <TextField
                  fullWidth
                  variant="filled"
                  type="datetime-local"
                  label="Fecha y hora de envío"
                  onBlur = {handleBlur}
                  onChange = {handleChange}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ min: minFechaHora }}
                  value = {values.send_date}
                  //value = { values.send_date===undefined?"":getFecha(values.send_date)}
                  //asp-format="{0:yyyy-MM-dd-hh:mm}"
                  name = "send_date"
                  error = {!!touched.send_date && !!errors.send_date}
                  helperText={touched.send_date && errors.send_date}
                  sx={{ gridColumn: "span 1"}}
                />

                <TextField
                  fullWidth
                  variant="filled"
                  label="Incluir Empleados"
                  select
                  onBlur = {handleBlur}
                  onChange = {(e) => {
                    handleChangeUbicacion(values,e.target);
                    setFieldValue("include_employees", e.target.value);
                  }}
                  value = {values.include_employees}
                  name = "include_employees"
                  sx={{ gridColumn: "span 1"}}
                  error = {!!touched.include_employees && !!errors.include_employees}
                  helperText={touched.include_employees && errors.include_employees}
                >
                  {incluir_empleados.map((incluye) => (
                    <MenuItem key={incluye.value} value={incluye.value}>
                      {incluye.label}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  fullWidth
                  variant="filled"
                  label="Enviar a todas las ubicaciones"
                  select
                  onBlur = {handleBlur}
                  onChange = {(e) => {
                    handleChangeUbicacion(values,e.target);
                    setFieldValue("location_all", e.target.value);
                  }}
                  value = {values.location_all}
                  name = "location_all"
                  sx={{ gridColumn: "span 1"}}
                  error = {!!touched.location_all && !!errors.location_all}
                  helperText={touched.location_all && errors.location_all}
                >
                  {todas_las_ubicaciones.map((todas) => (
                    <MenuItem key={todas.value} value={todas.value}>
                      {todas.label}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  fullWidth
                  variant="filled"
                  label="País"
                  select
                  disabled={values.location_all==="true"}
                  onBlur = {handleBlur}
                  onChange = {(e) => {
                    handleChangeUbicacion(values,e.target);
                    setFieldValue("country", e.target.value);
                    setFieldValue("department", "");
                    setFieldValue("city", "");
                  }}
                  value = {values.country}
                  name = "country"
                  sx={{ gridColumn: "span 1"}}
                  //error = {!!touched.country && !!errors.country}
                  //helperText={touched.country && errors.country}
                >
                  {paises.map((pais) => (
                    <MenuItem key={pais.value} value={pais.value}>
                      {pais.label}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  fullWidth
                  variant="filled"
                  label="Departamento"
                  select
                  disabled={values.country===""}
                  onBlur = {handleBlur}
                  onChange = {(e) => {
                    handleChangeUbicacion(values,e.target);
                    setFieldValue("department", e.target.value);
                    setFieldValue("city", "");
                  }}
                  value = {values.department}
                  name = "department"
                  sx={{ gridColumn: "span 1"}}
                  //error = {!!touched.departament && !!errors.departament}
                  //helperText={touched.departament && errors.departament}
                >
                  {departamentos[values.country]?departamentos[values.country].map((depto) => (
                    <MenuItem key={depto.value} value={depto.value}>
                      {depto.label}
                    </MenuItem>
                  )):[]}
                </TextField>

                <TextField
                  fullWidth
                  variant="filled"
                  label="Ciudad"
                  select
                  disabled={values.department===""}
                  onBlur = {handleBlur}
                  onChange = {(e) => {
                    handleChangeUbicacion(values,e.target);
                    setFieldValue("city", e.target.value);
                  }}
                  value = {values.city}
                  name = "city"
                  sx={{ gridColumn: "span 1"}}
                  //error = {!!touched.city && !!errors.city}
                  //helperText={touched.city && errors.city}
                >
                  {municipios[values.department]?municipios[values.department].map((mun) => (
                    <MenuItem key={mun.value} value={mun.value}>
                      {mun.label}
                    </MenuItem>
                  )):[]}
                </TextField>

                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Título"
                  onBlur = {handleBlur}
                  onChange = {handleChange}
                  value = { values.notification_title}
                  name = "notification_title"
                  error = {!!touched.notification_title && !!errors.notification_title}
                  helperText={touched.notification_title && errors.notification_title}
                  sx={{ gridColumn: "span 4"}}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  //type="text"
                  label="Contenido"
                  multiline
                  rows={6}
                  onBlur = {handleBlur}
                  onChange = {handleChange}
                  value = { values.body}
                  name = "body"
                  error = {!!touched.body && !!errors.body}
                  helperText={touched.body && errors.body}
                  sx={{ gridColumn: "span 4"}}
                />
              
              </Box>               
              <Box display="flex" justifyContent="end" mt="20px">
                <Button type="submit" color="secondary" variant="contained">
                  Programar Alerta
                </Button>            
              </Box> 
          </form>
        )}
      </Formik>    
    </Box>
  )
}

export default FormAlerta;