import { Box, TextField,Button, MenuItem} from "@mui/material"
import { Formik } from "formik"
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "./Header"
import { useEffect, useState } from "react";
import useAuth from "../auth/useAuth";
import url from "../config";
import { useNavigate } from "react-router-dom";
import swal from 'sweetalert';
import { useLocation } from "react-router-dom";
import { useFetch2 } from "../state/useFetch";
import { departamentos, municipios, paises } from "../data/ubicaciones";
import Loading from "../scenes/global/Loading";



const FormEmpleado = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const token = auth.globals.token
  const isNonMobile = useMediaQuery("(min-width:600px)")
  const [ loadingData, setLoadingData ] = useState(false)
  const [ error2, setError2 ] = useState(null)
  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const _id = query.get('_id')
  const initalData = {
    firstname: "",
    secondname: "",
    surname: "",
    lastname: "",
    identification: "",
    email: "",
    phone_number: "",
    birth_date: "",
    address: "",
    dep: "",
    city: "",
    country: "",
    status: "Active",
    imgURL: "",
    employee_id: ""
  }
  const {data,loading,error} = useFetch2(_id!=null?`${url}/api/employees/${_id}`:null,token,initalData)

  const mostrarAlerta=(error)=>{
    if(error!=null){
      try{
        swal({
          title:"Error al crear/modificar Colaborador",
          text: "Resultado: "+error,
          icon: "error",
          button:"Aceptar"
        })
        if(error==="No tiene autorización o su sesión ha expirado"){
          auth.logout();
        }
      }catch(e){
        swal({
          title:"Error al crear/modificar Colaborador",
          text: "Error inesperado ",
          icon: "error",
          button:"Aceptar"
        })
      }
    }
    setError2(null)
  }

  const mostrarAlertaVerEmpleado=(error)=>{
    if(error!=null){
      try{
        swal({
          title:"Colaborador no encontrado",
          text: "Resultado: "+error,
          icon: "error",
          button:"Aceptar"
        })
      }catch(e){
        swal({
          title:"Colaborador no encontrado",
          text: "Error inesperado ",
          icon: "error",
          button:"Aceptar"
        })
      }
    }
    navigate("/Colaboradores")
  }

  const alertSucess=(text_title,text_content='')=>{
    
    try{
      swal({
        title:text_title,
        text: text_content,
        icon: "success",
        button:"Aceptar"
      })
    }catch(e){
      swal({
        title:"Error inesperado",
        text: "Error inesperado ",
        icon: "error",
        button:"Aceptar"
      })
    }
    navigate("/Colaboradores")
  }

  const asyncsend2 = async(body) =>{
    try{
      
      let res = await fetch(url+"/api/employees", {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          "x-access-token": token,
        },
        body: JSON.stringify(body),
        cors: 'no-cors'
      })
      let data = await res.json()
      if(res.ok){
        setLoadingData(false)
        alertSucess(data.message,data.temp_password)
        navigate("/Equipo")       
      }else{
        setLoadingData(false)
        if(res.status===401){
          setError2("No tiene autorización o su sesión ha expirado")
        }else{
          setError2(data.message)
        }      
      }return data;
    }catch(error2){
      setLoadingData(false)
      setError2(error2)
    }
  }

  const asyncsend3 = async(body,id) =>{
    try{
      
      let res = await fetch(url+"/api/employees/"+id, {
        method: "PUT",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          "x-access-token": token,
        },
        body: JSON.stringify(body),
        cors: 'no-cors'
      })
      let data = await res.json()
      if(res.ok){
        setLoadingData(false)
        alertSucess(data.message)
        navigate("/Equipo")       
      }else{
        setLoadingData(false)
        if(res.status===401){
          setError2("No tiene autorización o su sesión ha expirado")
        }else{
          setError2(data.message)
        }      
      }return data;
    }catch(error2){
      setLoadingData(false)
      setError2(error2)
    }
  }

  const [initialValues,setInicitalValues] = useState({
    firstname: "",
    secondname: "",
    surname: "",
    lastname: "",
    identification: "",
    email: "",
    phone_number: "",
    birth_date: "",
    address: "",
    dep: "",
    city: "",
    country: "",
    status: "Active",
    imgURL: "",
    employee_id: ""
  })

  useEffect(()=>{
      if(_id){
        setInicitalValues(data)
      }      
  },[data,_id])

  const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

  const userSchema = yup.object().shape({
    firstname: yup.string().required("* Valor Requerido"),
    surname: yup.string().required("* Valor Requerido"),
    identification: yup.string().required("* Valor Requerido"),
    email: yup.string().email("Correo no valido").required("* Valor Requerido"),
    phone_number: yup.string().matches(phoneRegExp,"Número de telefono no valido").required("* Valor Requerido"),
    country: yup.string().required("* Valor Requerido"),
    dep: yup.string().required("* Valor Requerido"),
    city: yup.string().required("* Valor Requerido"),
    address: yup.string().required("* Valor Requerido"),
    birth_date: yup.date().required("* Valor Requerido"),

  })

  const getFecha =(fecha)=>{
    return fecha.slice(0,10)
  }

  const handleFormSubmit = (values) => {
    values.employee_id=values.identification
    setInicitalValues(values)
    setLoadingData(true)
    if(_id){
      asyncsend3(values,_id);
    }
    else{
      asyncsend2(values);
    }
  }

  const handleChangeUbicacion = (values,target) =>{
    values[target.name]=target.value;
  }

  if(error)
  return(mostrarAlertaVerEmpleado("Usuario no Encontrado"))
  else
  if(error2)
  return(mostrarAlerta(error2))
  else
  if(loadingData)
  return (<Loading/>)
  else
  if(loading)
  return (<Loading/>)

  else
  return (
    <Box m="20px">
      {!_id?
        <Header title="Nuevo Empleado" subtitle="Crear empleado nuevo"/>:
        <Header title={data.email} subtitle="Datos del empleado"/>}    

      <Formik
        enableReinitialize={true}
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema = {userSchema}
      >
        {({
          values, 
          errors, 
          touched, 
          handleBlur, 
          handleChange,
          handleSubmit,
          setFieldValue
        }) => (
          <form onSubmit={handleSubmit}>
              <Box 
                display="grid" 
                gap="30px" 
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": { gridColumn: isNonMobile? undefined : "span 4"}
                }}
              >               
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Primer Nombre"
                  onBlur = {handleBlur}
                  onChange = {handleChange}
                  value = { values.firstname}
                  name = "firstname"
                  error = {!!touched.firstname && !!errors.firstname}
                  helperText={touched.firstname && errors.firstname}
                  sx={{ gridColumn: "span 1"}}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Segundo Nombre"
                  onBlur = {handleBlur}
                  onChange = {handleChange}
                  value = { values.secondname}
                  name = "secondname"
                  error = {!!touched.secondname && !!errors.secondname}
                  helperText={touched.secondname && errors.secondname}
                  sx={{ gridColumn: "span 1"}}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Primer Apellido"
                  onBlur = {handleBlur}
                  onChange = {handleChange}
                  value = { values.surname}
                  name = "surname"
                  error = {!!touched.surname && !!errors.surname}
                  helperText={touched.surname && errors.surname}
                  sx={{ gridColumn: "span 1"}}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Segundo Apellido"
                  onBlur = {handleBlur}
                  onChange = {handleChange}
                  value = { values.lastname}
                  name = "lastname"
                  error = {!!touched.lastname && !!errors.lastname}
                  helperText={touched.lastname && errors.lastname}
                  sx={{ gridColumn: "span 1"}}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Identificación"
                  onBlur = {handleBlur}
                  onChange = {handleChange}
                  value = { values.identification}
                  name = "identification"
                  error = {!!touched.identification && !!errors.identification}
                  helperText={touched.identification && errors.identification}
                  sx={{ gridColumn: "span 2"}}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Email"
                  disabled={_id!=null}
                  onBlur = {handleBlur}
                  onChange = {handleChange}
                  value = { values.email}
                  name = "email"
                  error = {!!touched.email && !!errors.email}
                  helperText={touched.email && errors.email}
                  sx={{ gridColumn: "span 2"}}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="date"
                  label="Fecha de nacimiento"
                  onBlur = {handleBlur}
                  onChange = {handleChange}
                  value = { values.birth_date===undefined?"":getFecha(values.birth_date)}
                  asp-format="{0:yyyy-MM-dd}"
                  name = "birth_date"
                  error = {!!touched.birth_date && !!errors.birth_date}
                  helperText={touched.birth_date && errors.birth_date}
                  sx={{ gridColumn: "span 1"}}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Url imagen"
                  onBlur = {handleBlur}
                  onChange = {handleChange}
                  value = { values.imgURL}
                  name = "imgURL"
                  error = {!!touched.imgURL && !!errors.imgURL}
                  helperText={touched.imgURL && errors.imgURL}
                  sx={{ gridColumn: "span 3"}}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  label="País"
                  select
                  onBlur = {handleBlur}
                  onChange = {(e) => {
                    handleChangeUbicacion(values,e.target);
                    setFieldValue("country", e.target.value);
                    setFieldValue("dep", "");
                    setFieldValue("city", "");
                  }}
                  value = {values.country}
                  name = "country"
                  sx={{ gridColumn: "span 1"}}
                  error = {!!touched.country && !!errors.country}
                  helperText={touched.country && errors.country}
                >
                  {paises.map((pais) => (
                    <MenuItem key={pais.value} value={pais.value}>
                      {pais.label}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  fullWidth
                  variant="filled"
                  label="Departamento"
                  select
                  disabled={values.country===""}
                  onBlur = {handleBlur}
                  onChange = {(e) => {
                    handleChangeUbicacion(values,e.target);
                    setFieldValue("dep", e.target.value);
                    setFieldValue("city", "");
                  }}
                  value = {values.dep}
                  name = "dep"
                  sx={{ gridColumn: "span 1"}}
                  error = {!!touched.dep && !!errors.dep}
                  helperText={touched.dep && errors.dep}
                >
                  {departamentos[values.country]?departamentos[values.country].map((depto) => (
                    <MenuItem key={depto.value} value={depto.value}>
                      {depto.label}
                    </MenuItem>
                  )):[]}
                </TextField>

                <TextField
                  fullWidth
                  variant="filled"
                  label="Ciudad"
                  select
                  disabled={values.dep===""}
                  onBlur = {handleBlur}
                  onChange = {(e) => {
                    handleChangeUbicacion(values,e.target);
                    setFieldValue("city", e.target.value);
                  }}
                  value = {values.city}
                  name = "city"
                  sx={{ gridColumn: "span 1"}}
                  error = {!!touched.city && !!errors.city}
                  helperText={touched.city && errors.city}
                >
                  {municipios[values.dep]?municipios[values.dep].map((mun) => (
                    <MenuItem key={mun.value} value={mun.value}>
                      {mun.label}
                    </MenuItem>
                  )):[]}
                </TextField>
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Número de teléfono"
                  onBlur = {handleBlur}
                  onChange = {handleChange}
                  value = { values.phone_number}
                  name = "phone_number"
                  error = {!!touched.phone_number && !!errors.phone_number}
                  helperText={touched.phone_number && errors.phone_number}
                  sx={{ gridColumn: "span 1"}}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Dirección"
                  onBlur = {handleBlur}
                  onChange = {handleChange}
                  value = { values.address}
                  name = "address"
                  error = {!!touched.address && !!errors.address}
                  helperText={touched.address && errors.address}
                  sx={{ gridColumn: "span 3"}}
                />
                <TextField
                  disabled
                  fullWidth
                  variant="filled"
                  type="text"
                  label="ID Empleado"
                  onBlur = {handleBlur}
                  onChange = {handleChange}
                  value = { values.employee_id}
                  name = "employee_id"
                  error = {!!touched.employee_id && !!errors.employee_id}
                  helperText={touched.employee_id && errors.employee_id}
                  sx={{ gridColumn: "span 1"}}
                />              
              </Box>               
              <Box display="flex" justifyContent="end" mt="20px">
                <div>{!_id?
                  <Button type="submit" color="secondary" variant="contained">
                    Crear Nuevo Empleado
                  </Button>:
                  <Box >
                  <Button sx={{marginRight: "10px"}} type="submit" color="secondary" variant="contained">
                    Modificar
                  </Button>
                  {/*<Button type="button" color="success" variant="contained" onClick={()=>{navigate('/Equipo/Permisos')}}>
                    Permisos
                  </Button> */}                  
                  </Box>}    
                </div>            
              </Box> 
          </form>
        )}
      </Formik>     
    </Box>
  )
}

export default FormEmpleado;