import { BrowserRouter as Router, Route, Routes ,Navigate} from "react-router-dom";
import { ColorModeContext, useMode} from "../theme";
import { CssBaseline, ThemeProvider} from "@mui/material";

import Sidebar from "../scenes/global/Sidebar";
import Topbar from "../scenes/global/Topbar";
import Dashboard from "../scenes/pages/Dashboard";
import Clientes from "../scenes/pages/Clientes";
import Piscinas from "../scenes/pages/Piscinas";
import Empleados from "../scenes/pages/Empleados";
import LogIn from "../scenes/LogIn/LogIn";
import Mapa from "../scenes/pages/Mapa";
import initialState from "../state/state";
import useAuth from "../auth/useAuth";
import FormCliente from "../components/FormCliente";
import FormPiscina from "../components/FormPiscina";
import FormEmpleado from "../components/FormEmpleado";
import Revisiones from "../scenes/pages/Revisiones";
import Aplicaciones from "../scenes/pages/Aplicaciones";
import NoDataFound from "../scenes/global/NoDataFound";
import Footer from "../scenes/global/Footer";
import FormAlerta from "../scenes/pages/FormAlerta";

function AppRouter(){
    const estadoIncial = initialState.mode;
    const [theme, colorMode] = useMode(estadoIncial);
    const auth = useAuth();

  return(
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme = {theme}>
        <CssBaseline/>
        <div className="app">
          <Router>
            <Sidebar />
            <main className="content">
              <Topbar/>
              <Routes>
                <Route exact path="/Dashboard" element={auth.globals.auth?<Dashboard/>:<Navigate to="/" />} />
                <Route exact path="/Clientes" element={auth.globals.auth?<Clientes/>:<Navigate to="/" />} />
                <Route exact path="/Clientes/Form" element={auth.globals.auth?<FormCliente/>:<Navigate to="/" />} />
                <Route exact path="/Piscinas" element={auth.globals.auth?<Piscinas/>:<Navigate to="/" />} />
                <Route exact path="/Piscinas/Form" element={auth.globals.auth?<FormPiscina/>:<Navigate to="/" />} />
                <Route exact path="/Colaboradores" element={auth.globals.auth?<Empleados/>:<Navigate to="/" />} />
                <Route exact path="/Colaboradores/Form" element={auth.globals.auth?<FormEmpleado/>:<Navigate to="/" />} />
                <Route exact path="/Revisiones" element={auth.globals.auth?<Revisiones/>:<Navigate to="/" />} />
                <Route exact path="/Aplicaciones" element={auth.globals.auth?<Aplicaciones/>:<Navigate to="/" />} />
                <Route exact path="/Mapa" element={auth.globals.auth?<Mapa/>:<Navigate to="/" />} />
                <Route exact path="/Alertas" element={auth.globals.auth?<FormAlerta/>:<Navigate to="/" />} />
                <Route exact path="/" element={!auth.globals.auth?<LogIn/>:<Navigate to="/Dashboard" />} />
                <Route path="*" element={<NoDataFound/>} />
              </Routes>
            </main>
            <Footer/>
          </Router>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  )

}

export default AppRouter