import { ResponsiveBar } from "@nivo/bar";
import { useTheme } from "@mui/material";
import { tokens } from "../theme";



const BarChart = ({data,data3}) =>{
  const theme =useTheme();
  const colors = tokens(theme.palette.mode);

  const newData = []
  try{
    data?.forEach(function(piscina,index){
      let existe = 0;
      newData.forEach(function(dato,i){
        if (dato.Pais===piscina.country){
          dato.Piscinas += 1;
          existe = 1;
        }
      })
      if(existe===0){
        newData.push({Pais:piscina.country,Piscinas:1,Clientes:0})
      }
    })
  }
  catch(e){
    console.log("Error al clasificar Piscinas")
  }
  //console.log(newData)


  try{
    data3?.forEach(function(cliente,index){
      let existe = 0;
      newData.forEach(function(dato,i){
        if (dato.Pais===cliente.country){
          dato.Clientes += 1;
          existe = 1;
        }
      })
      if(existe===0){
        newData.push({Pais:cliente.country,Piscinas:0,Clientes:1})
      }
    })
  }
  catch(e){
    console.log("Error al clasificar Clientes")
  }
  //console.log(newData)

  return(
    <ResponsiveBar
      data={newData}
      theme={{
        // added
        axis: {
          domain: {
            line: {
              stroke: colors.grey[100],
            },
          },
          legend: {
            text: {
              fill: colors.grey[100],
            },
          },
          ticks: {
            line: {
              stroke: colors.grey[100],
              strokeWidth: 1,
            },
            text: {
              fill: colors.grey[100],
            },
          },
        },
        legends: {
          text: {
            fill: colors.grey[100],
          },
        },
        tooltip: {
          container: {
              "background": "#ffffff",
              "color": "#333333",
              "fontSize": 14
          },
          basic: {},
          chip: {},
          table: {},
          tableCell: {},
          tableCellValue: {}
        }
      }}
      keys={["Piscinas","Clientes"]}
      indexBy="Pais"
      margin={{ top: 50, right: 130, bottom: 120, left: 60 }}
      padding={0.3}
      groupMode="grouped"
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      colors={{ scheme: "paired" }}
      defs={[
        {
          id: "dots",
          type: "patternDots",
          background: "inherit",
          color: "#38bcb2",
          size: 4,
          padding: 1,
          stagger: true,
        },
        {
          id: "lines",
          type: "patternLines",
          background: "inherit",
          color: "#eed312",
          rotation: -45,
          lineWidth: 6,
          spacing: 10,
        },
      ]}
      borderColor={{
        from: "color",
        modifiers: [["darker", "1.6"]],
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 45,
        legend: "País", // changed
        legendPosition: "middle",
        legendOffset: 80,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "Cantidad", // changed
        legendPosition: "middle",
        legendOffset: -40,
      }}
      enableLabel={false}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{
        from: "color",
        modifiers: [["darker", 1.6]],
      }}
      legends={[
        {
          dataFrom: "keys",
          anchor: "bottom-right",
          direction: "column",
          justify: false,
          translateX: 120,
          translateY: 0,
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: "left-to-right",
          itemOpacity: 0.85,
          symbolSize: 20,
          effects: [
            {
              on: "hover",
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
      role="application"
      isFocusable={false}
      barAriaLabel={function (e) {
        return e.id + ": " + e.formattedValue + " in country: " + e.indexValue;
      }}
    />
  )
  
}

export default BarChart