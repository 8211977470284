import {MapContainer,TileLayer} from 'react-leaflet'
import 'leaflet/dist/leaflet.css'

import Markers from './Markers'

const GeoMap = ({ isDashboard = false, data }) => {
  let latitud ='12.664825'
  let longitud ='-86.210384'
  let zoom = 6


  if(isDashboard){
    latitud='15.874317'
    longitud='-90.390008'
    zoom = 6
  }



  
  return (
    <MapContainer
      center={{lat:latitud, lng:longitud}}
      zoom={zoom}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        
        //Mapa vial
        //url="http://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}"
        
        //Terreno
        //url="http://mt0.google.com/vt/lyrs=p&hl=en&x={x}&y={y}&z={z}"

        //Hoja de ruta alterada
        //url="http://mt0.google.com/vt/lyrs=r&hl=en&x={x}&y={y}&z={z}"

        //Solo satélite
        //url="http://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}"

        //Solo terreno
        //url="http://mt0.google.com/vt/lyrs=t&hl=en&x={x}&y={y}&z={z}"

        //Híbrido
        //url="http://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}"
              />
      <Markers data={data}/>
    </MapContainer>
  )

}    


export default GeoMap