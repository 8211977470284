import { Box,Button,useTheme } from "@mui/material";
import { DataGrid, esES, GridToolbar } from "@mui/x-data-grid";
import useAuth from "../../auth/useAuth";
import Header from "../../components/Header"
import url from "../../config";
import { useFetch2 } from "../../state/useFetch";
import { tokens } from "../../theme";
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from "react-router-dom";
import Loading from "../global/Loading";
import FatalError from "../global/FatalError";

const Clientes = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode)
  const auth = useAuth();
  const token = auth.globals.token
  const {data,loading,error} = useFetch2(`${url}/api/clients`,token)

  const navigate = useNavigate();

  const columns=[
    {
      field:"_id", 
      headerName: "ID",
      flex: 1,
      hide: true,
      hideable : false,
    },
    {
      field:"email", 
      headerName: "Email", 
      flex: 1.25, 
      cellClassName: "email-column--cell"
    },
    {
      field:"firstname", 
      headerName: "Primer Nombre",
      flex: 0.75, 
      cellClassName: "firstname-column--cell"
    },
    {
      field:"secondname", 
      headerName: "Segundo Nombre",
      flex: 0.75, 
      cellClassName: "secondname-column--cell",
      hide: true,
    },
    {
      field:"surname", 
      headerName: "Primer apellido",
      flex: 0.75, 
      cellClassName: "surname-column--cell"
    },
    {
      field:"lastname", 
      headerName: "Segundo apellido",
      flex: 0.75, 
      cellClassName: "lastname-column--cell",
      hide: true,
    },
    {
      field:"identification", 
      headerName: "Identificacion",
      flex: 0.75, 
      cellClassName: "identification-column--cell",
    },
    {
      field:"phone_number", 
      headerName: "Telefono",
      flex: 0.75
    },
    {
      field:"country", 
      headerName: "País",
      flex: 0.75
    },
    {
      field:"dep", 
      headerName: "Departamento",
      flex: 0.75
    },
    {
      field:"city", 
      headerName: "Municipio",
      flex: 0.75
    },
    {
      field:"status", 
      headerName: "Estado",
      flex: 0.5, 
      cellClassName: "status-column--cell"
    },
    {
      field:"createdAt", 
      headerName: "Fecha Creación",
      flex: 1, 
      cellClassName: "createdAt-column--cell",
      hide: true,
      type: 'dateTime',
      valueGetter: ({ value }) => value && new Date(value)
    },
    {
      field:"updatedAt", 
      headerName: "Fecha Modificacion",
      flex: 1, 
      cellClassName: "updatedAt-column--cell",
      hide: true,
      type: 'dateTime',
      valueGetter: ({ value }) => value && new Date(value)
    },
    {
      field:"actions", 
      headerName: "Ver",
      flex: 0.75, 
      cellClassName: "actions-column--cell",
      renderCell:({ row: { _id,email } })=>{
        return(
          <Box
            width="80%"
            maxWidth="175px"
            height="75%"
            maxHeight="35px" 
            display="flex" 
            justifyContent="center" 
            m="0 auto">
            <Button
              color="secondary"
              variant="contained"
              onClick={()=>{navigate("/Clientes/Form?_id="+_id+"&_user="+email)}}>
            <VisibilityIcon/>
              <Box sx={{ml:"5px"}}>
                Ver
              </Box>
            </Button>
          </Box>
        )
      }
    }
  ]

  if(loading)
  return (<Loading/>)

  if(error)
  return(<FatalError/>)

  return (
    <Box p={2}>
      {/*<Box display="flex" justifyContent="space-between" alignItems="center">*/}
        <Header title="CLIENTES" subtitle="Listado de Clientes"/>
          <Box display="flex" justifyContent="end" mt="20px">
            <Button
              color="secondary"
              variant="contained"
              onClick={()=>{navigate("/Clientes/Form")}}>
              <AddIcon/>
              Nuevo Cliente
            </Button>
          </Box>
          {/*<Box
              width="100px"
              p= "5px"
              display="flex"
              justifyContent="center"
              backgroundColor= {colors.greenAccent[600]}
              borderRadius = "4px"
              onClick={()=>{navigate("/Clientes/Form")}}
              >
              <PersonAddIcon/>
              <Typography color={colors.grey[100]} sx={{ml:"5px"}}>
                Nuevo
              </Typography>
          </Box>*/}

        <Box m="5px 0 0 0" height="67vh" sx={{
          "& .MuiDataGrid-root":{
            border: "none",
          },
          "& .MuiDataGrid-cell":{
            borderBottom: "none"
          },
          "& .email-column--cell":{
            color: colors.greenAccent[300]
          },
          "& .MuiDataGrid-columnHeaders":{
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
            color: "white"
          },
          "& .MuiDataGrid-virtualScroller":{
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer":{
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text":{
            color: `${colors.grey[100]} !important`,
          },
          "& .MuiToolbar-gutters":{
            color: "white"
          }

        }}>
          <DataGrid
            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
            rows={data}
            columns={columns}
            getRowId={(row) => row._id}
            components={{Toolbar: GridToolbar}}
            /*defaultHiddenColumnNames ={['_id']}*/
          />
        </Box>
      {/*</Box>*/}
    </Box>
  );
}

export default Clientes;