import { Box, TextField} from "@mui/material"
import { Formik } from "formik"
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "./Header"
import { useEffect, useState } from "react";
import useAuth from "../auth/useAuth";
import url from "../config";
import { useNavigate } from "react-router-dom";
import swal from 'sweetalert';
import { useLocation } from "react-router-dom";
import { useFetch2 } from "../state/useFetch";
import Revisiones from "../scenes/pages/Revisiones";
import Aplicaciones from "../scenes/pages/Aplicaciones";
import Pumps from "./Pumps";
import Loading from "../scenes/global/Loading";

const FormPiscina = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const token = auth.globals.token
  const isNonMobile = useMediaQuery("(min-width:600px)")
  const [ error2, setError2 ] = useState(null)
  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const _id = query.get('_id')
  const initialData = {
    poolname: "",
    user_email: "",
    ph_ideal: "",
    address: "",
    dep: "",
    city: "",
    country: "",
    longitude: "",
    latitude: "",
    gallonage: "",
    roofed: "",
    area: "",
    length: "",
    width: "",
    use_type: "",
    diameter: "",
    volume: "",
    ideal_chlorine: "",
    average_depth: "",
    total_alkalinity: "",
    type: "",
    img_URL: "",
    status: "",
  }
  const {data,loading,error} = useFetch2(_id!=null?`${url}/api/pools/${_id}`:null,token,initialData)

  const mostrarAlerta=(error)=>{
    if(error!=null){
      try{
        swal({
          title:"Error al crear/modificar Piscina",
          text: "Resultado: "+error,
          icon: "error",
          button:"Aceptar"
        })
      }catch(e){
        swal({
          title:"Error al crear/modificar Piscina",
          text: "Error inesperado ",
          icon: "error",
          button:"Aceptar"
        })
      }
    }
    setError2(null)
  }

  const mostrarAlertaVerPiscina=(error)=>{
    if(error!=null){
      try{
        swal({
          title:"Piscina no encontrada",
          text: "Resultado: "+error,
          icon: "error",
          button:"Aceptar"
        })
      }catch(e){
        swal({
          title:"Pisicina no encontrada",
          text: "Error inesperado ",
          icon: "error",
          button:"Aceptar"
        })
      }
    }
    navigate("/Piscinas")
  }

  const [initialValues,setInicitalValues] = useState({
    poolname: "",
    user_email: "",
    ph_ideal: "",
    address: "",
    dep: "",
    city: "",
    country: "",
    longitude: "",
    latitude: "",
    gallonage: "",
    roofed: "",
    area: "",
    length: "",
    width: "",
    use_type: "",
    diameter: "",
    volume: "",
    ideal_chlorine: "",
    average_depth: "",
    total_alkalinity: "",
    type: "",
    img_URL: "",
    status: "",
  })

  useEffect(()=>{
      if(_id){
        setInicitalValues(data)
      }    
  },[data,_id])

  
  const userSchema = yup.object().shape({
    poolname: yup.string().required("* Valor Requerido"),
    user_email: yup.string().email("Correo no valido").required("* Valor Requerido"),
    ph_ideal: yup.number().required("* Valor Requerido"),
    address: yup.string().required("* Valor Requerido"),
    dep: yup.string().required("* Valor Requerido"),
    city: yup.string().required("* Valor Requerido"),
    country: yup.string().required("* Valor Requerido"),
    longitude: yup.string().required("* Valor Requerido"),
    latitude: yup.string().required("* Valor Requerido"),
    gallonage: yup.string().required("* Valor Requerido"),
    roofed: yup.string().required("* Valor Requerido"),
    length: yup.string().required("* Valor Requerido"),
    width: yup.string().required("* Valor Requerido"),
    type: yup.string().required("* Valor Requerido"),
    img_URL: yup.string().required("* Valor Requerido"),
    status: yup.string().required("* Valor Requerido")


  })


  const handleFormSubmit = (values) => {
    console.log("Envío")
  }

  if(error)
  return(mostrarAlertaVerPiscina("Usuario no Encontrado"))
  else
  if(error2)
  return(mostrarAlerta(error2))
  else
  if(loading)
  return (<Loading/>)

  else
  return (
    <Box m="20px">
      {!_id?
        <Header title="Nueva Piscina" subtitle="Crear piscina nueva"/>:
        <Header title={data.poolname} subtitle="Datos de la piscina"/>}    


      <Formik
        enableReinitialize={true}
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema = {userSchema}
      >
        {({
          values, 
          errors, 
          touched, 
          handleBlur, 
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
              <Box 
                display="grid" 
                gap="30px" 
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": { gridColumn: isNonMobile? undefined : "span 4"}
                }}
              >               
                <TextField
                  disabled
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Nombre Piscina"
                  onBlur = {handleBlur}
                  onChange = {handleChange}
                  value = { values.poolname}
                  name = "poolname"
                  error = {!!touched.poolname && !!errors.poolname}
                  helperText={touched.poolname && errors.poolname}
                  sx={{ gridColumn: "span 1"}}
                />
                <TextField
                  disabled
                  fullWidth
                  variant="filled"
                  type="text"
                  label="País"
                  onBlur = {handleBlur}
                  onChange = {handleChange}
                  value = { values.country}
                  name = "country"
                  error = {!!touched.country && !!errors.country}
                  helperText={touched.country && errors.country}
                  sx={{ gridColumn: "span 1"}}
                />
                <TextField
                  disabled
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Departamento"
                  onBlur = {handleBlur}
                  onChange = {handleChange}
                  value = { values.dep}
                  name = "dep"
                  error = {!!touched.dep && !!errors.dep}
                  helperText={touched.dep && errors.dep}
                  sx={{ gridColumn: "span 1"}}
                />
                <TextField
                  disabled
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Ciudad"
                  onBlur = {handleBlur}
                  onChange = {handleChange}
                  value = { values.city}
                  name = "city"
                  error = {!!touched.city && !!errors.city}
                  helperText={touched.city && errors.city}
                  sx={{ gridColumn: "span 1"}}
                />                
                <TextField
                  disabled
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Dirección"
                  onBlur = {handleBlur}
                  onChange = {handleChange}
                  value = { values.address}
                  name = "address"
                  error = {!!touched.address && !!errors.address}
                  helperText={touched.address && errors.address}
                  sx={{ gridColumn: "span 4"}}
                />                
                <TextField
                  disabled
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Email Usuario"
                  onBlur = {handleBlur}
                  onChange = {handleChange}
                  value = { values.user_email}
                  name = "user_email"
                  error = {!!touched.user_email && !!errors.user_email}
                  helperText={touched.user_email && errors.user_email}
                  sx={{ gridColumn: "span 2"}}
                />                
                <TextField
                  disabled
                  fullWidth
                  variant="filled"
                  type="text"
                  label="URL imagen"
                  onBlur = {handleBlur}
                  onChange = {handleChange}
                  value = { values.img_URL}
                  name = "img_URL"
                  error = {!!touched.img_URL && !!errors.img_URL}
                  helperText={touched.img_URL && errors.img_URL}
                  sx={{ gridColumn: "span 2"}}
                />
                <TextField
                  disabled
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Latitud"
                  onBlur = {handleBlur}
                  onChange = {handleChange}
                  value = { values.latitude}
                  name = "latitude"
                  error = {!!touched.latitude && !!errors.latitude}
                  helperText={touched.latitude && errors.latitude}
                  sx={{ gridColumn: "span 1"}}
                />                
                <TextField
                  disabled
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Longitud"
                  onBlur = {handleBlur}
                  onChange = {handleChange}
                  value = { values.longitude}
                  name = "longitude"
                  error = {!!touched.longitude && !!errors.longitude}
                  helperText={touched.longitude && errors.longitude}
                  sx={{ gridColumn: "span 1"}}
                />
                <TextField
                  disabled
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Galonaje"
                  onBlur = {handleBlur}
                  onChange = {handleChange}
                  value = { values.gallonage}
                  name = "gallonage"
                  error = {!!touched.gallonage && !!errors.gallonage}
                  helperText={touched.gallonage && errors.gallonage}
                  sx={{ gridColumn: "span 1"}}
                />
                <TextField
                  disabled
                  fullWidth
                  variant="filled"
                  type="number"
                  label="PH Ideal"
                  onBlur = {handleBlur}
                  onChange = {handleChange}
                  value = { values.ph_ideal}
                  name = "ph_ideal"
                  error = {!!touched.ph_ideal && !!errors.ph_ideal}
                  helperText={touched.ph_ideal && errors.ph_ideal}
                  sx={{ gridColumn: "span 1"}}
                />                
                <TextField
                  disabled
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Techado"
                  onBlur = {handleBlur}
                  onChange = {handleChange}
                  value = { values.roofed}
                  name = "roofed"
                  error = {!!touched.roofed && !!errors.roofed}
                  helperText={touched.roofed && errors.roofed}
                  sx={{ gridColumn: "span 1"}}
                />
                <TextField
                  disabled
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Largo"
                  onBlur = {handleBlur}
                  onChange = {handleChange}
                  value = { values.length}
                  name = "length"
                  error = {!!touched.length && !!errors.length}
                  helperText={touched.length && errors.length}
                  sx={{ gridColumn: "span 1"}}
                />
                <TextField
                  disabled
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Ancho"
                  onBlur = {handleBlur}
                  onChange = {handleChange}
                  value = { values.width}
                  name = "width"
                  error = {!!touched.width && !!errors.width}
                  helperText={touched.width && errors.width}
                  sx={{ gridColumn: "span 1"}}
                />                

                <TextField
                  disabled
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Profundidad promedio"
                  onBlur = {handleBlur}
                  onChange = {handleChange}
                  value = { values.average_depth}
                  name = "average_depth"
                  error = {!!touched.average_depth && !!errors.average_depth}
                  helperText={touched.average_depth && errors.average_depth}
                  sx={{ gridColumn: "span 1"}}
                />
                <TextField
                  disabled
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Tipo de uso"
                  onBlur = {handleBlur}
                  onChange = {handleChange}
                  value = { values.use_type}
                  name = "use_type"
                  error = {!!touched.use_type && !!errors.use_type}
                  helperText={touched.use_type && errors.use_type}
                  sx={{ gridColumn: "span 1"}}
                />
                <TextField
                  disabled
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Figura"
                  onBlur = {handleBlur}
                  onChange = {handleChange}
                  value = { values.type}
                  name = "type"
                  error = {!!touched.type && !!errors.type}
                  helperText={touched.type && errors.type}
                  sx={{ gridColumn: "span 1"}}
                />                
                <TextField
                  disabled
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Diametro"
                  onBlur = {handleBlur}
                  onChange = {handleChange}
                  value = { values.diameter}
                  name = "diameter"
                  error = {!!touched.diameter && !!errors.diameter}
                  helperText={touched.diameter && errors.diameter}
                  sx={{ gridColumn: "span 1"}}
                />
                <TextField
                  disabled
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Área"
                  onBlur = {handleBlur}
                  onChange = {handleChange}
                  value = { values.area}
                  name = "area"
                  error = {!!touched.area && !!errors.area}
                  helperText={touched.area && errors.area}
                  sx={{ gridColumn: "span 1"}}
                />                
                <TextField
                  disabled
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Volumen"
                  onBlur = {handleBlur}
                  onChange = {handleChange}
                  value = { values.volume}
                  name = "volume"
                  error = {!!touched.volume && !!errors.volume}
                  helperText={touched.volume && errors.volume}
                  sx={{ gridColumn: "span 1"}}
                />
                <TextField
                  disabled
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Cloro ideal"
                  onBlur = {handleBlur}
                  onChange = {handleChange}
                  value = { values.ideal_chlorine}
                  name = "ideal_chlorine"
                  error = {!!touched.ideal_chlorine && !!errors.ideal_chlorine}
                  helperText={touched.ideal_chlorine && errors.ideal_chlorine}
                  sx={{ gridColumn: "span 1"}}
                />
                <TextField
                  disabled
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Alcalinidad"
                  onBlur = {handleBlur}
                  onChange = {handleChange}
                  value = { values.total_alkalinity}
                  name = "total_alkalinity"
                  error = {!!touched.total_alkalinity && !!errors.total_alkalinity}
                  helperText={touched.total_alkalinity && errors.total_alkalinity}
                  sx={{ gridColumn: "span 1"}}
                />
                <TextField
                  disabled
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Estado"
                  onBlur = {handleBlur}
                  onChange = {handleChange}
                  value = { values.status}
                  name = "status"
                  error = {!!touched.status && !!errors.status}
                  helperText={touched.status && errors.status}
                  sx={{ gridColumn: "span 1"}}
                />
              
              </Box>               
              {/*<Box display="flex" justifyContent="end" mt="20px">
                <div>{!_id?
                  <Button type="submit" color="secondary" variant="contained">
                    Crear Nuevo Cliente
                  </Button>:
                  <Button type="submit" color="secondary" variant="contained">
                    Modificar
                  </Button>}    
                </div>           
              </Box>*/} 
          </form>
        )}
      </Formik>
      {/*<Alerta error={error}/>*/}
      {_id!=null?<Revisiones/>:undefined}
      {_id!=null?<Aplicaciones/>:undefined}
      {_id!=null?<Pumps/>:undefined}      
    </Box>
  )
}

export default FormPiscina;