import { useEffect } from "react";
import { createContext,useState } from "react";

export const AuthContext = createContext()

const AuthProvider =({children}) =>{
  /*const [auth,setAuth] = useState(JSON.parse(localStorage.getItem("auth")) || false);*/
  const [globals,setGlobals] = useState(JSON.parse(localStorage.getItem("globals")) || 
    {
        auth: false,
        token: null,
        username: null,
        type: null,
        roles: {}
    }
  );

  useEffect(()=> {
    try{
      localStorage.setItem("globals",JSON.stringify(globals));
    }catch(error){
      localStorage.removeItem("globals");
      console.log(error);
    }
  },[globals])
  
  const contextValue = {
    globals,
    login(username,token,type,roles){
      if(username!=null && token !=null && type!=null && roles !=null){
        setGlobals(
          {
            auth: true,
            token: token,
            username: username,
            type: type,
            roles: roles
          }
        );
      }else{
        setGlobals(globals)
      }

    },
    logout(){
        setGlobals(
          {
            auth: false,
            token: null,
            username: null,
            type: null,
            roles: {}
          }
        );
    },
    isLogged(){
      return!!globals.auth;
    }
  }
  
  return(
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  )
}


export default AuthProvider