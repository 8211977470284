import React from 'react'
import{ Marker, Popup } from 'react-leaflet'
import {IconMarker} from './IconMarker';

const Markers = ({data=[{latitude:'',longitude:''}]}) =>{
  const markers = data.map((piscina,i) => (
    <Marker
      key={i}
      position={{lat:piscina.latitude,lng:piscina.longitude}} 
      icon={IconMarker}
    >
      <Popup>
        <b>{piscina.user_email}</b> <br /> {piscina.poolname}.
      </Popup>
    </Marker>
  ))
  return markers

}

export default Markers;