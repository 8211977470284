import { useTheme } from "@emotion/react";
import { Box } from "@mui/material";
import Header from "../../components/Header"
import { tokens } from "../../theme";
import GeoMap from "../../components/GeoMap"
import useAuth from "../../auth/useAuth";
import { useFetch2 } from "../../state/useFetch";
import url from "../../config";
import Loading from "../global/Loading";
import FatalError from "../global/FatalError";

const Mapa = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode)
  const auth = useAuth();
  const token = auth.globals.token
  const {data,loading,error} = useFetch2(`${url}/api/pools`,token)

  if(loading)
  return (<Loading/>)

  if(error)
  return(<FatalError/>)

  return (
    <Box p={2}>
      <Header title="MAPA" subtitle="Ubicación de piscinas"/>
      <Box
          height="67vh"
          border={`1px solid ${colors.grey[100]}`}
          borderRadius="4px"
        >
          <GeoMap data={data} />
      </Box>
    </Box>
  );
}

export default Mapa;