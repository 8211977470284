import { useState, useEffect } from 'react'
import swal from 'sweetalert'
import useAuth from '../auth/useAuth'


const UseFetch = (url,myVar,token) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [error,setError] = useState(null)

  useEffect(() =>{
    const fetchResource = async () => {
      try{
        /*console.log('se intenta')*/
        let res = await fetch(url, {
          method: "POST",
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "x-access-token": token,
          },
          body: JSON.stringify(myVar),
          cors: 'no-cors'
        })
        let data = await res.json()
        /*console.log('data del fetch      1',data)*/
        setData(data)
        setLoading(false)

      }catch (error){
        setLoading(false)
        setError(error)
      }
    }
    fetchResource()
  },[url,myVar,token])
  /*console.log('data del fetch',data)*/
  return{data,loading,error}
}
/*export default useFetch*/


const useFetch2 = (url,token,initial) => {
  const [data, setData] = useState(initial)
  const [loading, setLoading] = useState(true)
  const [error,setError] = useState(null)

  const auth = useAuth();

  const mostrarAlerta=()=>{
    swal({
      title:"No autorizado",
      text: "No tiene autorización para permanecer en esta página o su sesión ha expirado",
      icon: "error",
      button:"Aceptar"
    })
  }

  useEffect(() =>{
    const fetchResource = async () => {
      if(url!=null){
        try{
          /*console.log('se intenta')*/
          let res = await fetch(url, {
            method: "GET",
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
              "x-access-token": token,
            },
            cors: 'no-cors'
          })
          let data = await res.json()
          /*console.log('data del fetch      1',data)*/
          if(res.ok){
            setData(data)
          }else{
            if(res.status===401){
              mostrarAlerta();
              auth.logout();
            }
            setError(data.message)
          }
          setLoading(false)
  
        }catch (error){
          setLoading(false)
          setError(error)
        }        
      }else{
        setLoading(false)
      }
    }
    fetchResource()
  },[url,token,auth])
  /*console.log('data del fetch',data)*/
  return{data,loading,error}
}

const useFetch3 = (url,token,initial) => {
  const [data3, setData3] = useState(initial)
  const [loading3, setLoading3] = useState(true)
  const [error3,setError3] = useState(null)

  const auth = useAuth();

  const mostrarAlerta=()=>{
    swal({
      title:"No autorizado",
      text: "No tiene autorización para permanecer en esta página o su sesión ha expirado",
      icon: "error",
      button:"Aceptar"
    })
  }

  useEffect(() =>{
    const fetchResource = async () => {
      if(url!=null){
        try{
          /*console.log('se intenta')*/
          let res = await fetch(url, {
            method: "GET",
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
              "x-access-token": token,
            },
            cors: 'no-cors'
          })
          let data3 = await res.json()
          /*console.log('data del fetch      1',data)*/
          if(res.ok){
            setData3(data3)
          }else{
            if(res.status===401){
              mostrarAlerta();
              auth.logout();
            }
            setError3(data3.message)
          }
          setLoading3(false)
  
        }catch (error3){
          setLoading3(false)
          setError3(error3)
        }        
      }else{
        setLoading3(false)
      }
    }
    fetchResource()
  },[url,token,auth])
  /*console.log('data del fetch',data)*/
  return{data3,loading3,error3}
}

const useFetch4 = (url,token,initial) => {
  const [data4, setData4] = useState(initial)
  const [loading4, setLoading4] = useState(true)
  const [error4,setError4] = useState(null)

  const auth = useAuth();

  const mostrarAlerta=()=>{
    swal({
      title:"No autorizado",
      text: "No tiene autorización para permanecer en esta página o su sesión ha expirado",
      icon: "error",
      button:"Aceptar"
    })
  }

  useEffect(() =>{
    const fetchResource = async () => {
      if(url!=null){
        try{
          /*console.log('se intenta')*/
          let res = await fetch(url, {
            method: "GET",
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
              "x-access-token": token,
            },
            cors: 'no-cors'
          })
          let data4 = await res.json()
          /*console.log('data del fetch      1',data)*/
          if(res.ok){
            setData4(data4)
          }else{
            if(res.status===401){
              mostrarAlerta();
              auth.logout();
            }
            setError4(data4.message)
          }
          setLoading4(false)
  
        }catch (error4){
          setLoading4(false)
          setError4(error4)
        }        
      }else{
        setLoading4(false)
      }
    }
    fetchResource()
  },[url,token,auth])
  /*console.log('data del fetch',data)*/
  return{data4,loading4,error4}
}

const useFetch5 = (url,token,initial) => {
  const [data5, setData5] = useState(initial)
  const [loading5, setLoading5] = useState(true)
  const [error5,setError5] = useState(null)

  const auth = useAuth();

  const mostrarAlerta=()=>{
    swal({
      title:"No autorizado",
      text: "No tiene autorización para permanecer en esta página o su sesión ha expirado",
      icon: "error",
      button:"Aceptar"
    })
  }

  useEffect(() =>{
    const fetchResource = async () => {
      if(url!=null){
        try{
          /*console.log('se intenta')*/
          let res = await fetch(url, {
            method: "GET",
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
              "x-access-token": token,
            },
            cors: 'no-cors'
          })
          let data5 = await res.json()
          /*console.log('data del fetch      1',data)*/
          if(res.ok){
            setData5(data5)
          }else{
            if(res.status===401){
              mostrarAlerta();
              auth.logout();
            }
            setError5(data5.message)
          }
          setLoading5(false)
  
        }catch (error5){
          setLoading5(false)
          setError5(error5)
        }        
      }else{
        setLoading5(false)
      }
    }
    fetchResource()
  },[url,token,auth])
  /*console.log('data del fetch',data)*/
  return{data5,loading5,error5}
}

const useFetch6 = (url,token,initial) => {
  const [data6, setData6] = useState(initial)
  const [loading6, setLoading6] = useState(true)
  const [error6,setError6] = useState(null)

  const auth = useAuth();

  const mostrarAlerta=()=>{
    swal({
      title:"No autorizado",
      text: "No tiene autorización para permanecer en esta página o su sesión ha expirado",
      icon: "error",
      button:"Aceptar"
    })
  }

  useEffect(() =>{
    const fetchResource = async () => {
      if(url!=null){
        try{
          /*console.log('se intenta')*/
          let res = await fetch(url, {
            method: "GET",
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
              "x-access-token": token,
            },
            cors: 'no-cors'
          })
          let data6 = await res.json()
          /*console.log('data del fetch      1',data)*/
          if(res.ok){
            setData6(data6)
          }else{
            if(res.status===401){
              mostrarAlerta();
              auth.logout();
            }
            setError6(data6.message)
          }
          setLoading6(false)
  
        }catch (error6){
          setLoading6(false)
          setError6(error6)
        }        
      }else{
        setLoading6(false)
      }
    }
    fetchResource()
  },[url,token,auth])
  /*console.log('data del fetch',data)*/
  return{data6,loading6,error6}
}

const useFetch7 = (url,token,initial) => {
  const [data7, setData7] = useState(initial)
  const [loading7, setLoading7] = useState(true)
  const [error7,setError7] = useState(null)

  const auth = useAuth();

  const mostrarAlerta=()=>{
    swal({
      title:"No autorizado",
      text: "No tiene autorización para permanecer en esta página o su sesión ha expirado",
      icon: "error",
      button:"Aceptar"
    })
  }

  useEffect(() =>{
    const fetchResource = async () => {
      if(url!=null){
        try{
          /*console.log('se intenta')*/
          let res = await fetch(url, {
            method: "GET",
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
              "x-access-token": token,
            },
            cors: 'no-cors'
          })
          let data7 = await res.json()
          /*console.log('data del fetch      1',data)*/
          if(res.ok){
            setData7(data7)
          }else{
            if(res.status===401){
              mostrarAlerta();
              auth.logout();
            }
            setError7(data7.message)
          }
          setLoading7(false)
  
        }catch (error7){
          setLoading7(false)
          setError7(error7)
        }        
      }else{
        setLoading7(false)
      }
    }
    fetchResource()
  },[url,token,auth])
  /*console.log('data del fetch',data)*/
  return{data7,loading7,error7}
}


const useFetch8 = (url,token,initial) => {
  const [data8, setData8] = useState(initial)
  const [loading8, setLoading8] = useState(true)
  const [error8,setError8] = useState(null)

  const auth = useAuth();

  const mostrarAlerta=()=>{
    swal({
      title:"No autorizado",
      text: "No tiene autorización para permanecer en esta página o su sesión ha expirado",
      icon: "error",
      button:"Aceptar"
    })
  }

  useEffect(() =>{
    const fetchResource = async () => {
      if(url!=null){
        try{
          /*console.log('se intenta')*/
          let res = await fetch(url, {
            method: "GET",
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
              "x-access-token": token,
            },
            cors: 'no-cors'
          })
          let data8 = await res.json()
          /*console.log('data del fetch      1',data)*/
          if(res.ok){
            setData8(data8)
          }else{
            if(res.status===401){
              mostrarAlerta();
              auth.logout();
            }
            setError8(data8.message)
          }
          setLoading8(false)
  
        }catch (error8){
          setLoading8(false)
          setError8(error8)
        }        
      }else{
        setLoading8(false)
      }
    }
    fetchResource()
  },[url,token,auth])
  /*console.log('data del fetch',data)*/
  return{data8,loading8,error8}
}

const useFetch9 = (url,token,initial) => {
  const [data9, setData9] = useState(initial)
  const [loading9, setLoading9] = useState(true)
  const [error9,setError9] = useState(null)

  const auth = useAuth();

  const mostrarAlerta=()=>{
    swal({
      title:"No autorizado",
      text: "No tiene autorización para permanecer en esta página o su sesión ha expirado",
      icon: "error",
      button:"Aceptar"
    })
  }

  useEffect(() =>{
    const fetchResource = async () => {
      if(url!=null){
        try{
          /*console.log('se intenta')*/
          let res = await fetch(url, {
            method: "GET",
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
              "x-access-token": token,
            },
            cors: 'no-cors'
          })
          let data9 = await res.json()
          /*console.log('data del fetch      1',data)*/
          if(res.ok){
            setData9(data9)
          }else{
            if(res.status===401){
              mostrarAlerta();
              auth.logout();
            }
            setError9(data9.message)
          }
          setLoading9(false)
  
        }catch (error9){
          setLoading9(false)
          setError9(error9)
        }        
      }else{
        setLoading9(false)
      }
    }
    fetchResource()
  },[url,token,auth])
  /*console.log('data del fetch',data)*/
  return{data9,loading9,error9}
}

export { UseFetch, useFetch2, useFetch3, useFetch4, useFetch5, useFetch6, useFetch7, useFetch8, useFetch9}