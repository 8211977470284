import { Box, Typography, useTheme } from "@mui/material"
import { tokens } from "../theme";
import ProgressCircle from "./ProgressCircle";

const StatBox =({title,subtitle2,icon,progress,increase,subtitle1,valor})=>{
  const theme = useTheme();
  const colors = tokens(theme.palette.mode)

  return(
    <Box width="100%" m="0 40px">
      <Box display="flex" justifyContent="space-between">
        <Box>
          {icon}
          <Typography 
            variant="h4" 
            fontWeight="bold"
            sx={{color:colors.grey[100]}}
          >
            {title}
          </Typography>
        </Box>
        <Box>
          <ProgressCircle progress={progress}/>
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between" mt="2px">
        <Typography variant="h4" sx={{color: colors.greenAccent[600]}}>
          {subtitle1}
        </Typography>
        <Typography 
          variant="h4" 
          fontStyle="italic"
          sx={{color:colors.greenAccent[600]}}
        >
          {valor}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="space-between" mt="2px">
        <Typography variant="h5" sx={{color: colors.greenAccent[600]}}>
          {subtitle2}
        </Typography>
        <Typography 
          variant="h5" 
          fontStyle="italic"
          sx={{color:colors.greenAccent[600]}}
        >
          {increase}
        </Typography>
      </Box>
    </Box>      
  )
}

export default StatBox;