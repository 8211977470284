import { Box,useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useLocation } from "react-router-dom";
import useAuth from "../auth/useAuth";
import { useFetch2 } from "../state/useFetch";
import url from "../config";
import Header from "./Header";
import { tokens } from "../theme";
import Loading from "../scenes/global/Loading";
import FatalError from "../scenes/global/FatalError";

const Pumps = () => {


  const theme = useTheme();
  const colors = tokens(theme.palette.mode)
  const auth = useAuth();
  const token = auth.globals.token

  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const _user = query.get('_user')
  const _id = query.get('_id')

  const {data,loading,error} = useFetch2(_user!=null?`${url}/api/pumps/user/${_user}`:_id!=null?`${url}/api/pumps/bypool/${_id}`:`${url}/api/pumps`,token)

  const columns=[
    {
      field:"_id", 
      headerName: "ID",
      hide: true,
      hideable : false,
    },
    {
      field:"user_email", 
      headerName: "Usuario", 
      flex: 1, 
      cellClassName: "user_email-column--cell"
    },
    {
      field:"name", 
      headerName: "Nombre Bomba",
      flex: 1, 
      cellClassName: "name-column--cell"
    },
    {
      field:"brand", 
      headerName: "Marca",
      flex: 1, 
      cellClassName: "brand-column--cell"
    },
    {
      field:"filter", 
      headerName: "Filtro",
      flex: 1, 
      cellClassName: "filter-column--cell"
    },
    {
      field:"gpm", 
      headerName: "gal/min",
      flex: 1, 
      cellClassName: "gpm-column--cell"
    },
    {
      field:"voltaje", 
      headerName: "Voltaje",
      flex: 1, 
      cellClassName: "voltaje-column--cell"
    },
    {
      field:"hp", 
      headerName: "Caballos de Fuerza",
      flex: 1, 
      cellClassName: "hp-column--cell"
    },
    {
      field:"status", 
      headerName: "Estado",
      flex: 1, 
      cellClassName: "status-column--cell"
    },
  ]

  if(loading)
  return (<Loading/>)

  if(error)
  return(<FatalError/>)

  return (
    <Box m="20px">
      {/*<Box display="flex" justifyContent="space-between" alignItems="center">*/}
        <Header title={_user!=null?"":_id!=null?"":"BOMBAS"} subtitle="Listado de Bombas"/>

        <Box m="20px 0 0 0" height="65vh" sx={{
          "& .MuiDataGrid-root":{
            border: "none",
          },
          "& .MuiDataGrid-cell":{
            borderBottom: "none"
          },
          "& .email-column--cell":{
            color: colors.greenAccent[300]
          },
          "& .MuiDataGrid-columnHeaders":{
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
            color: "white"
          },
          "& .MuiDataGrid-virtualScroller":{
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer":{
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text":{
            color: `${colors.grey[100]} !important`,
          },
          "& .MuiToolbar-gutters":{
            color: "white"
          }

        }}>
          <DataGrid
            rows={data}
            columns={columns}
            getRowId={(row) => row._id}
            components={{Toolbar: GridToolbar}}
          />
        </Box>
    </Box>
  );


}

export default Pumps;