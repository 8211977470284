export const paises = [
  {
    value:"Guatemala",
    label:"Guatemala"
  },
  {
    value: "Honduras",
    label: "Honduras" 
  },
  {
    value: "El Salvador",
    label: "El Salvador"
  },
  { 
    value: "Nicaragua",
    label: "Nicaragua"
  },
  {
    value: "Costa Rica",
    label: "Costa Rica"
  },
  {
    value: "Panamá",
    label: "Panamá"
  }
]


export const departamentos = {
  Guatemala: [
    { label: 'Alta Verapaz', value: 'Alta Verapaz' },
    { label: 'Baja Verapaz', value: 'Baja Verapaz' },
    { label: 'Chimaltenango', value: 'Chimaltenango' },
    { label: 'Chiquimula', value: 'Chiquimula' },
    { label: 'El Progreso', value: 'El Progreso' },
    { label: 'Escuintla', value: 'Escuintla' },
    { label: 'Guatemala', value: 'Guatemala' },
    { label: 'Huehuetenango', value: 'Huehuetenango' },
    { label: 'Izabal', value: 'Izabal' },
    { label: 'Jalapa', value: 'Jalapa' },
    { label: 'Jutiapa', value: 'Jutiapa' },
    { label: 'Petén', value: 'Petén' },
    { label: 'Quetzaltenango', value: 'Quetzaltenango' },
    { label: 'Quiché', value: 'Quiché' },
    { label: 'Retalhuleu', value: 'Retalhuleu' },
    { label: 'Sacatepéquez', value: 'Sacatepéquez' },
    { label: 'San Marcos', value: 'San Marcos' },
    { label: 'Santa Rosa', value: 'Santa Rosa' },
    { label: 'Sololá', value: 'Sololá' },
    { label: 'Suchitepéquez', value: 'Suchitepéquez' },
    { label: 'Totonicapán', value: 'Totonicapán' },
    { label: 'Zacapa', value: 'Zacapa' },
  ],
  Honduras: [
    { value: "Atlántida", label: "Atlántida" },  
    { value: "Choluteca", label: "Choluteca" },  
    { value: "Colón (Honduras)", label: "Colón" },  
    { value: "Comayagua", label: "Comayagua" },  
    { value: "Copán", label: "Copán" },  
    { value: "Cortés", label: "Cortés" },  
    { value: "El Paraíso", label: "El Paraíso" },  
    { value: "Francisco Morazán", label: "Francisco Morazán" },  
    { value: "Gracias a Dios", label: "Gracias a Dios" },  
    { value: "Intibucá", label: "Intibucá" },  
    { value: "Islas de la Bahía", label: "Islas de la Bahía" },  
    { value: "La Paz (Honduras)", label: "La Paz" },  
    { value: "Lempira", label: "Lempira" },  
    { value: "Ocotepeque", label: "Ocotepeque" },  
    { value: "Olancho", label: "Olancho" },  
    { value: "Santa Bárbara", label: "Santa Bárbara" },  
    { value: "Valle", label: "Valle" },  
    { value: "Yoro", label: "Yoro" }
  ],
  "El Salvador":[
    { value: "Ahuachapán", label: "Ahuachapán" },  
    { value: "Cabañas", label: "Cabañas" },  
    { value: "Chalatenango", label: "Chalatenango" },  
    { value: "Cuscatlán", label: "Cuscatlán" },  
    { value: "La Libertad", label: "La Libertad" },  
    { value: "La Paz", label: "La Paz" },  
    { value: "La Unión", label: "La Unión" },  
    { value: "Morazán", label: "Morazán" },  
    { value: "San Miguel", label: "San Miguel" },  
    { value: "San Salvador", label: "San Salvador" },  
    { value: "Santa Ana", label: "Santa Ana" },  
    { value: "San Vicente", label: "San Vicente" },  
    { value: "Sonsonate", label: "Sonsonate" },  
    { value: "Usulután", label: "Usulután" }
  ],
  Nicaragua:[
    { value: "Boaco", label: "Boaco" },  
    { value: "Carazo", label: "Carazo" },  
    { value: "Chinandega", label: "Chinandega" },  
    { value: "Chontales", label: "Chontales" },  
    { value: "Estelí", label: "Estelí" },  
    { value: "Granada", label: "Granada" },  
    { value: "Jinotega", label: "Jinotega" },  
    { value: "León", label: "León" },  
    { value: "Madriz", label: "Madriz" },  
    { value: "Managua", label: "Managua" },  
    { value: "Masaya", label: "Masaya" },  
    { value: "Matagalpa", label: "Matagalpa" },  
    { value: "Nueva Segovia", label: "Nueva Segovia" },  
    { value: "Rivas", label: "Rivas" },  
    { value: "Río San Juan", label: "Río San Juan" }
  ],
  "Costa Rica":[
    { value: "San José", label: "San José" },  
    { value: "Alajuela", label: "Alajuela" },  
    { value: "Cartago", label: "Cartago" },  
    { value: "Heredia", label: "Heredia" },  
    { value: "Guanacaste", label: "Guanacaste" },  
    { value: "Puntarenas", label: "Puntarenas" },  
    { value: "Limón", label: "Limón" }
  ],
  "Panamá":[
    { value: "Bocas del Toro", label: "Bocas del Toro" },  
    { value: "Coclé", label: "Coclé" },  
    { value: "Colón (Panamá)", label: "Colón" },  
    { value: "Chiriquí", label: "Chiriquí" },  
    { value: "Darién", label: "Darién" },  
    { value: "Herrera", label: "Herrera" },  
    { value: "Los Santos", label: "Los Santos" },  
    { value: "Panamá", label: "Panamá" },  
    { value: "Veraguas", label: "Veraguas" },  
    { value: "Panamá Oeste", label: "Panamá Oeste" }
  ]
}


export const municipios = {
  //GUATEMALA
  "Alta Verapaz": [
    { value:"Chahal", label:"Chahal"},
    { value:"Chisec", label:"Chisec"},
    { value:"Cobán", label:"Cobán"},
    { value:"Fray Bartolomé de las Casas", label:"Fray Bartolomé de las Casas"},
    { value:"Lanquín", label:"Lanquín"},
    { value:"Panzós", label:"Panzós"},
    { value:"Raxruha", label:"Raxruha"},
    { value:"San Cristóbal Verapaz", label:"San Cristóbal Verapaz"},
    { value:"San Juan Chamelco", label:"San Juan Chamelco"},
    { value:"San Pedro Carchá", label:"San Pedro Carchá"},
    { value:"Santa Cruz Verapaz", label:"Santa Cruz Verapaz"},
    { value:"Senahú", label:"Senahú"},
    { value:"Tactic", label:"Tactic"},
    { value:"Tamahú", label:"Tamahú"},
    { value:"Tucurú", label:"Tucurú"},
    { value:"Santa María Cahabón", label:"Santa María Cahabón"},
    { value:"Santa Catarina La Tinta", label:"Santa María Cahabón"}
  ],
  "Baja Verapaz": [
    { value: "Cubulco", label: "Cubulco" },
    { value: "Granados", label: "Granados" },
    { value: "Purulhá", label: "Purulhá" },
    { value: "Rabinal", label: "Rabinal" },
    { value: "Salamá", label: "Salamá" },
    { value: "San Jerónimo", label: "San Jerónimo" },
    { value: "San Miguel Chicaj", label: "San Miguel Chicaj" },
    { value: "Santa Cruz El Chol", label: "Santa Cruz El Chol" }
  ],
  Chimaltenango: [
    { value: "Acatenango", label: "Acatenango" },
    { value: "Chimaltenango", label: "Chimaltenango" },
    { value: "El Tejar", label: "El Tejar" },
    { value: "Parramos", label: "Parramos" },
    { value: "Patzicía", label: "Patzicía" },
    { value: "Patzún", label: "Patzún" },
    { value: "Pochuta", label: "Pochuta" },
    { value: "San Andrés Itzapa", label: "San Andrés Itzapa" },
    { value: "San José Poaquil", label: "San José Poaquil" },
    { value: "San Juan Comalapa", label: "San Juan Comalapa" },
    { value: "San Martín Jilotepeque", label: "San Martín Jilotepeque" },
    { value: "Santa Apolonia", label: "Santa Apolonia" },
    { value: "Santa Cruz Balanyá", label: "Santa Cruz Balanyá" },
    { value: "Tecpán Guatemala", label: "Tecpán Guatemala" },
    { value: "Yepocapa", label: "Yepocapa" },
    { value: "Zaragoza", label: "Zaragoza" }
  ],
  Chiquimula:[
    { value: "Camotán", label:"Camotán" },
    { value: "Chiquimula", label:"Chiquimula" },
    { value: "Concepción Las Minas", label:"Concepción Las Minas" },
    { value: "Esquipulas", label:"Esquipulas" },
    { value: "Ipala", label:"Ipala" },
    { value: "Jocotán", label:"Jocotán" },
    { value: "Olopa", label:"Olopa" },
    { value: "Quezaltepeque", label:"Quezaltepeque" },
    { value: "San Jacinto", label:"San Jacinto" },
    { value: "San José La Arada", label:"San José La Arada" },
    { value: "San Juan Ermita", label:"San Juan Ermita" }    
  ],
  "El Progreso":[
    { value:"El Jícaro", label:"El Jícaro"},
    { value:"Guastatoya", label:"Guastatoya"},
    { value:"Morazán", label:"Morazán"},
    { value:"San Agustín Acasaguastlán", label:"San Agustín Acasaguastlán"},
    { value:"San Antonio La Paz", label:"San Antonio La Paz"},
    { value:"San Cristóbal Acasaguastlán", label:"San Cristóbal Acasaguastlán"},
    { value:"Sanarate", label:"Sanarate"}
  ],
  Escuintla:[
    { value:"Escuintla", label:"Escuintla"},
    { value:"Guanagazapa", label:"Guanagazapa"},
    { value:"Iztapa", label:"Iztapa"},
    { value:"La Democracia", label:"La Democracia"},
    { value:"La Gomera", label:"La Gomera"},
    { value:"Masagua", label:"Masagua"},
    { value:"Nueva Concepción", label:"Nueva Concepción"},
    { value:"Palín", label:"Palín"},
    { value:"San José", label:"San José"},
    { value:"San Vicente Pacaya", label:"San Vicente Pacaya"},
    { value:"Santa Lucía Cotzumalguapa", label:"Santa Lucía Cotzumalguapa"},
    { value:"Siquinalá", label:"Siquinalá"},
    { Value:"Tiquisate", label:"Tiquisate"}
  ],
  Guatemala:[
    { value: "Amatitlán", label:"Amatitlán"},
    { value: "Chinautla", label:"Chinautla"},
    { value: "Chuarrancho", label:"Chuarrancho"},
    { value: "Fraijanes", label:"Fraijanes"},
    { value: "Guatemala", label:"Guatemala"},
    { value: "Mixco", label:"Mixco"},
    { value: "Palencia", label:"Palencia"},
    { value: "Petapa", label:"Petapa"},
    { value: "San José del Golfo", label:"San José del Golfo"},
    { value: "San José Pinula", label:"San José Pinula"},
    { value: "San Juan Sacatepéquez", label:"San Juan Sacatepéquez"},
    { value: "San Pedro Ayampuc", label:"San Pedro Ayampuc"},
    { value: "San Pedro Sacatepéquez", label:"San Pedro Sacatepéquez"},
    { value: "San Raymundo", label:"San Raymundo"},
    { value: "Santa Catarina Pinula", label:"Santa Catarina Pinula"},
    { value: "Villa Canales", label:"Villa Canales"},
    { value: "Villa Nueva", label:"Villa Nueva"}
  ],
  Huehuetenango:[
    { value: "Aguacatán", label: "Aguacatán" },  
    { value: "Chiantla", label: "Chiantla" },  
    { value: "Colotenango", label: "Colotenango" },  
    { value: "Concepción Huista", label: "Concepción Huista" },  
    { value: "Cuilco", label: "Cuilco" },  
    { value: "Huehuetenango", label: "Huehuetenango" },  
    { value: "Jacaltenango", label: "Jacaltenango" },  
    { value: "La Democracia", label: "La Democracia" },  
    { value: "La Libertad", label: "La Libertad" },  
    { value: "Malacatancito", label: "Malacatancito" },  
    { value: "Nentón", label: "Nentón" },  
    { value: "Petatán", label: "Petatán" },  
    { value: "San Antonio Huista", label: "San Antonio Huista" },  
    { value: "San Gaspar Ixchil", label: "San Gaspar Ixchil" },  
    { value: "San Ildefonso Ixtahuacán", label: "San Ildefonso Ixtahuacán" },  
    { value: "San Juan Atitán", label: "San Juan Atitán" },  
    { value: "San Juan Ixcoy", label: "San Juan Ixcoy" },  
    { value: "San Mateo Ixtatán", label: "San Mateo Ixtatán" },  
    { value: "San Miguel Acatán", label: "San Miguel Acatán" },  
    { value: "San Pedro Necta", label: "San Pedro Necta" },  
    { value: "San Pedro Soloma", label: "San Pedro Soloma" },  
    { value: "San Rafael La Independencia", label: "San Rafael La Independencia" },  
    { value: "San Rafael Petzal", label: "San Rafael Petzal" },  
    { value: "San Sebastián Coatán", label: "San Sebastián Coatán" },  
    { value: "San Sebastián Huehuetenango", label: "San Sebastián Huehuetenango" },  
    { value: "Santa Ana Huista", label: "Santa Ana Huista" },  
    { value: "Santa Bárbara", label: "Santa Bárbara" },  
    { value: "Santa Cruz Barillas", label: "Santa Cruz Barillas" },  
    { value: "Santa Eulalia", label: "Santa Eulalia" },  
    { value: "Santiago Chimaltenango", label: "Santiago Chimaltenango" },  
    { value: "Tectitán", label: "Tectitán" },  
    { value: "Todos Santos Cuchumatán", label: "Todos Santos Cuchumatán" }
  ],
  Izabal: [
    { value: "El Estor", label: "El Estor" },  
    { value: "Livingston", label: "Livingston" },  
    { value: "Los Amates", label: "Los Amates" },  
    { value: "Morales", label: "Morales" },  
    { value: "Puerto Barrios", label: "Puerto Barrios" }
  ],
  Jalapa: [
    { value: "Jalapa", label: "Jalapa" },  
    { value: "Mataquescuintla", label: "Mataquescuintla" },  
    { value: "Monjas", label: "Monjas" },  
    { value: "San Carlos Alzatate", label: "San Carlos Alzatate" },  
    { value: "San Luis Jilotepeque", label: "San Luis Jilotepeque" },  
    { value: "San Manuel Chaparrón", label: "San Manuel Chaparrón" },  
    { value: "San Pedro Pinula", label: "San Pedro Pinula" }
  ],
  Jutiapa: [
    { value: "Agua Blanca", label: "Agua Blanca" },  
    { value: "Asunción Mita", label: "Asunción Mita" },  
    { value: "Atescatempa", label: "Atescatempa" },  
    { value: "Comapa", label: "Comapa" },  { value: "Conguaco", label: "Conguaco" },  
    { value: "El Adelanto", label: "El Adelanto" },  { value: "El Progreso", label: "El Progreso" },  
    { value: "Jalpatagua", label: "Jalpatagua" },  
    { value: "Jerez", label: "Jerez" },  
    { value: "Jutiapa", label: "Jutiapa" },  
    { value: "Moyuta", label: "Moyuta" },  
    { value: "Pasaco", label: "Pasaco" },  
    { value: "Quezada", label: "Quezada" },  
    { value: "San José Acatempa", label: "San José Acatempa" },  
    { value: "Santa Catarina Mita", label: "Santa Catarina Mita" },  
    { value: "Yupiltepeque", label: "Yupiltepeque" },  
    { value: "Zapotitlán", label: "Zapotitlán" }
  ],
  "Petén": [
    { value: "Dolores", label: "Dolores" },  
    { value: "El Chal", label: "El Chal" },  
    { value: "Flores", label: "Flores" },  
    { value: "La Libertad", label: "La Libertad" },  
    { value: "Melchor de Mencos", label: "Melchor de Mencos" },  
    { value: "Poptún", label: "Poptún" },  
    { value: "San Andrés", label: "San Andrés" },  
    { value: "San Benito", label: "San Benito" },  
    { value: "San Francisco", label: "San Francisco" },  
    { value: "San José", label: "San José" },  
    { value: "San Luis", label: "San Luis" },  
    { value: "Santa Ana", label: "Santa Ana" },  
    { value: "Sayaxché", label: "Sayaxché" },  
    { value: "Tikal Futura", label: "Tikal Futura" },  
    { value: "Uaxactún", label: "Uaxactún" }
  ],
  Quetzaltenango:[
    { value: "Almolonga", label: "Almolonga" },  
    { value: "Cabricán", label: "Cabricán" },  
    { value: "Cajolá", label: "Cajolá" },  
    { value: "Cantel", label: "Cantel" },  
    { value: "Coatepeque", label: "Coatepeque" },  
    { value: "Colomba", label: "Colomba" },  
    { value: "Concepción Chiquirichapa", label: "Concepción Chiquirichapa" },  
    { value: "El Palmar", label: "El Palmar" },  
    { value: "Flores Costa Cuca", label: "Flores Costa Cuca" },  
    { value: "Génova", label: "Génova" },  
    { value: "Huitán", label: "Huitán" },  
    { value: "La Esperanza", label: "La Esperanza" },  
    { value: "Olintepeque", label: "Olintepeque" },  
    { value: "Palestina de los Altos", label: "Palestina de los Altos" },  
    { value: "Quetzaltenango", label: "Quetzaltenango" },  
    { value: "Salcajá", label: "Salcajá" },  
    { value: "San Juan Ostuncalco", label: "San Juan Ostuncalco" },  
    { value: "San Martín Sacatepéquez", label: "San Martín Sacatepéquez" },  
    { value: "San Mateo", label: "San Mateo" },  
    { value: "San Miguel Sigüilá", label: "San Miguel Sigüilá" },  
    { value: "Sibilia", label: "Sibilia" },  
    { value: "Zunil", label: "Zunil" }
  ],
  "Quiché":[
    { value: "Canillá", label: "Canillá" },  
    { value: "Chajul", label: "Chajul" },  
    { value: "Chicamán", label: "Chicamán" },  
    { value: "Chiché", label: "Chiché" },  
    { value: "Chinique", label: "Chinique" },  
    { value: "Cunén", label: "Cunén" },  
    { value: "Ixcán", label: "Ixcán" },  
    { value: "Joyabaj", label: "Joyabaj" },  
    { value: "Nebaj", label: "Nebaj" },  
    { value: "Pachalum", label: "Pachalum" },  
    { value: "Patzité", label: "Patzité" },  
    { value: "Sacapulas", label: "Sacapulas" },  
    { value: "San Andrés Sajcabajá", label: "San Andrés Sajcabajá" },  
    { value: "San Antonio Ilotenango", label: "San Antonio Ilotenango" },  
    { value: "San Bartolomé Jocotenango", label: "San Bartolomé Jocotenango" },  
    { value: "San Juan Cotzal", label: "San Juan Cotzal" },  
    { value: "San Pedro Jocopilas", label: "San Pedro Jocopilas" },  
    { value: "Santa Cruz del Quiché", label: "Santa Cruz del Quiché" },  
    { value: "Uspantán", label: "Uspantán" },  
    { value: "Zacualpa", label: "Zacualpa" }
  ],
  Retalhuleu:[
    { value: "Champerico", label: "Champerico" },  
    { value: "El Asintal", label: "El Asintal" },  
    { value: "Nuevo San Carlos", label: "Nuevo San Carlos" },  
    { value: "Retalhuleu", label: "Retalhuleu" },  
    { value: "San Andrés Villa Seca", label: "San Andrés Villa Seca" },  
    { value: "San Felipe", label: "San Felipe" },  
    { value: "San Martín Zapotitlán", label: "San Martín Zapotitlán" }
  ],
  "Sacatepéquez":[
    { value: "Alotenango", label: "Alotenango" },  
    { value: "Antigua Guatemala", label: "Antigua Guatemala" },  
    { value: "Ciudad Vieja", label: "Ciudad Vieja" },  
    { value: "Jocotenango", label: "Jocotenango" },  
    { value: "Magdalena Milpas Altas", label: "Magdalena Milpas Altas" },  
    { value: "Pastores", label: "Pastores" },  
    { value: "San Antonio Aguas Calientes", label: "San Antonio Aguas Calientes" },  
    { value: "San Bartolomé Milpas Altas", label: "San Bartolomé Milpas Altas" },  
    { value: "San Lucas Sacatepéquez", label: "San Lucas Sacatepéquez" },  
    { value: "San Miguel Dueñas", label: "San Miguel Dueñas" },  
    { value: "Santa Catarina Barahona", label: "Santa Catarina Barahona" },  
    { value: "Santa Lucía Milpas Altas", label: "Santa Lucía Milpas Altas" },  
    { value: "Santa María de Jesús", label: "Santa María de Jesús" },  
    { value: "Santiago Sacatepéquez", label: "Santiago Sacatepéquez" },  
    { value: "Santo Domingo Xenacoj", label: "Santo Domingo Xenacoj" }
  ],
  "San Marcos":[
    { value: "Ayutla", label: "Ayutla" },  
    { value: "Catarina", label: "Catarina" },  
    { value: "Comitancillo", label: "Comitancillo" },  
    { value: "Concepción Tutuapa", label: "Concepción Tutuapa" },  
    { value: "El Quetzal", label: "El Quetzal" },  
    { value: "El Rodeo", label: "El Rodeo" },  
    { value: "El Tumbador", label: "El Tumbador" },  
    { value: "Esquipulas Palo Gordo", label: "Esquipulas Palo Gordo" },  
    { value: "Ixchiguán", label: "Ixchiguán" },  
    { value: "La Reforma", label: "La Reforma" },  
    { value: "Malacatán", label: "Malacatán" },  
    { value: "Nuevo Progreso", label: "Nuevo Progreso" },  
    { value: "Ocos", label: "Ocos" },  
    { value: "Pajapita", label: "Pajapita" },  
    { value: "Río Blanco", label: "Río Blanco" },  
    { value: "San Antonio Sacatepéquez", label: "San Antonio Sacatepéquez" },  
    { value: "San Cristóbal Cucho", label: "San Cristóbal Cucho" },  
    { value: "San José Ojetenam", label: "San José Ojetenam" },  
    { value: "San Lorenzo", label: "San Lorenzo" },  
    { value: "San Marcos", label: "San Marcos" },  
    { value: "San Miguel Ixtahuacán", label: "San Miguel Ixtahuacán" },  
    { value: "San Pablo", label: "San Pablo" },  
    { value: "San Pedro Sacatepéquez", label: "San Pedro Sacatepéquez" },  
    { value: "San Rafael Pie de la Cuesta", label: "San Rafael Pie de la Cuesta" },  
    { value: "Sibinal", label: "Sibinal" },  { value: "Sipacapa", label: "Sipacapa" },  
    { value: "Tacaná", label: "Tacaná" },  { value: "Tajumulco", label: "Tajumulco" },  
    { value: "Tejutla", label: "Tejutla" }
  ],
  "Santa Rosa":[
    { value: "Barberena", label: "Barberena" },  
    { value: "Casillas", label: "Casillas" },  
    { value: "Chiquimulilla", label: "Chiquimulilla" },  
    { value: "Cuilapa", label: "Cuilapa" },  
    { value: "El Chal", label: "El Chal" },  
    { value: "Guazacapán", label: "Guazacapán" },  
    { value: "Nueva Santa Rosa", label: "Nueva Santa Rosa" },  
    { value: "Oratorio", label: "Oratorio" },  
    { value: "Pueblo Nuevo Viñas", label: "Pueblo Nuevo Viñas" },  
    { value: "San Juan Tecuaco", label: "San Juan Tecuaco" },  
    { value: "San Rafael Las Flores", label: "San Rafael Las Flores" },  
    { value: "Santa Cruz Naranjo", label: "Santa Cruz Naranjo" },  
    { value: "Santa María Ixhuatán", label: "Santa María Ixhuatán" },  
    { value: "Santa Rosa de Lima", label: "Santa Rosa de Lima" },  
    { value: "Taxisco", label: "Taxisco" }
  ],
  "Sololá":[
    { value: "Concepción", label: "Concepción" },  
    { value: "Nahualá", label: "Nahualá" },  
    { value: "Panajachel", label: "Panajachel" },  
    { value: "San Andrés Semetabaj", label: "San Andrés Semetabaj" },  
    { value: "San Antonio Palopó", label: "San Antonio Palopó" },  
    { value: "San José Chacayá", label: "San José Chacayá" },  
    { value: "San Juan La Laguna", label: "San Juan La Laguna" },  
    { value: "San Lucas Tolimán", label: "San Lucas Tolimán" },  
    { value: "San Marcos La Laguna", label: "San Marcos La Laguna" },  
    { value: "San Pablo La Laguna", label: "San Pablo La Laguna" },  
    { value: "San Pedro La Laguna", label: "San Pedro La Laguna" },  
    { value: "Santa Catarina Ixtahuacán", label: "Santa Catarina Ixtahuacán" },  
    { value: "Santa Catarina Palopó", label: "Santa Catarina Palopó" },  
    { value: "Santa Clara La Laguna", label: "Santa Clara La Laguna" },  
    { value: "Santa Cruz La Laguna", label: "Santa Cruz La Laguna" },  
    { value: "Santa Lucía Utatlán", label: "Santa Lucía Utatlán" },  
    { value: "Santa María Visitación", label: "Santa María Visitación" },  
    { value: "Santiago Atitlán", label: "Santiago Atitlán" }
  ],
  "Suchitepéquez":[
    { value: "Chicacao", label: "Chicacao" },  
    { value: "Cuyotenango", label: "Cuyotenango" },  
    { value: "Mazatenango", label: "Mazatenango" },  
    { value: "Patulul", label: "Patulul" },  
    { value: "Pueblo Nuevo", label: "Pueblo Nuevo" },  
    { value: "Río Bravo", label: "Río Bravo" },  
    { value: "Samayac", label: "Samayac" },  
    { value: "San Antonio Suchitepéquez", label: "San Antonio Suchitepéquez" },  
    { value: "San Bernardino", label: "San Bernardino" },  
    { value: "San Francisco Zapotitlán", label: "San Francisco Zapotitlán" },  
    { value: "San Gabriel", label: "San Gabriel" },  
    { value: "San José El Ídolo", label: "San José El Ídolo" },  
    { value: "San Juan Bautista", label: "San Juan Bautista" },  
    { value: "San Lorenzo", label: "San Lorenzo" },  
    { value: "San Miguel Panán", label: "San Miguel Panán" },  
    { value: "San Pablo Jocopilas", label: "San Pablo Jocopilas" },  
    { value: "Santa Bárbara", label: "Santa Bárbara" },  
    { value: "Santo Domingo Suchitepéquez", label: "Santo Domingo Suchitepéquez" },  
    { value: "Zunilito", label: "Zunilito" }
  ],
  "Totonicapán":[
    { value: "Momostenango", label: "Momostenango" },  
    { value: "San Andrés Xecul", label: "San Andrés Xecul" },  
    { value: "San Bartolo", label: "San Bartolo" },  
    { value: "San Cristóbal Totonicapán", label: "San Cristóbal Totonicapán" },  
    { value: "San Francisco El Alto", label: "San Francisco El Alto" },  
    { value: "Santa Lucía La Reforma", label: "Santa Lucía La Reforma" },  
    { value: "Santa María Chiquimula", label: "Santa María Chiquimula" },  
    { value: "Totonicapán", label: "Totonicapán" }
  ],
  Zacapa:[
    { value: "Cabañas", label: "Cabañas" },  
    { value: "Estanzuela", label: "Estanzuela" },  
    { value: "Gualán", label: "Gualán" },  
    { value: "Huité", label: "Huité" },  
    { value: "La Unión", label: "La Unión" },  
    { value: "Río Hondo", label: "Río Hondo" },  
    { value: "San Diego", label: "San Diego" },  
    { value: "Teculután", label: "Teculután" },  
    { value: "Usumatlán", label: "Usumatlán" },  
    { value: "Zacapa", label: "Zacapa" }
  ],
  //HONDURAS
  "Atlántida":[
    {value: "La Ceiba", label: "La Ceiba"},    
    {value: "El Porvenir", label: "El Porvenir"},    
    {value: "Esparta", label: "Esparta"},    
    {value: "Jutiapa", label: "Jutiapa"},    
    {value: "La Masica", label: "La Masica"},    
    {value: "San Francisco", label: "San Francisco"},    
    {value: "Tela", label: "Tela"},    
    {value: "Arizona", label: "Arizona"},    
    {value: "Laureles", label: "Laureles"},    
    {value: "San Juan", label: "San Juan"}
  ],
  Choluteca:[
    {value: "Choluteca", label: "Choluteca"},    
    {value: "Apacilagua", label: "Apacilagua"},    
    {value: "Concepción de María", label: "Concepción de María"},    
    {value: "Duyure", label: "Duyure"},    
    {value: "El Corpus", label: "El Corpus"},    
    {value: "El Triunfo", label: "El Triunfo"},    
    {value: "Marcovia", label: "Marcovia"},    
    {value: "Morolica", label: "Morolica"},    
    {value: "Namasigüe", label: "Namasigüe"},    
    {value: "Orocuina", label: "Orocuina"},    
    {value: "Pespire", label: "Pespire"},    
    {value: "San Antonio de Flores", label: "San Antonio de Flores"},    
    {value: "San Isidro", label: "San Isidro"},    
    {value: "San José", label: "San José"},    
    {value: "San Marcos de Colón", label: "San Marcos de Colón"}
  ],
  "Colón (Honduras)":[
    {value: "Trujillo", label: "Trujillo"},    
    {value: "Balfate", label: "Balfate"},    
    {value: "Bonito Oriental", label: "Bonito Oriental"},    
    {value: "Iriona", label: "Iriona"},    
    {value: "Limón", label: "Limón"},    
    {value: "Sabá", label: "Sabá"},    
    {value: "Santa Fe", label: "Santa Fe"},    
    {value: "Santa Rosa de Aguán", label: "Santa Rosa de Aguán"},    
    {value: "Sonaguera", label: "Sonaguera"},    
    {value: "Tocoa", label: "Tocoa"},    
    {value: "Olanchito", label: "Olanchito"},    
    {value: "San Francisco de Becerra", label: "San Francisco de Becerra"}
  ],
  Comayagua:[
    {value:"Comayagua", label:"Comayagua"},  
    {value:"Ajuterique", label:"Ajuterique"},  
    {value:"El Rosario", label:"El Rosario"},  
    {value:"Esquías", label:"Esquías"},  
    {value:"Humuya", label:"Humuya"},  
    {value:"La Libertad", label:"La Libertad"},  
    {value:"La Trinidad", label:"La Trinidad"},  
    {value:"Lamaní", label:"Lamaní"},  
    {value:"Las Lajas", label:"Las Lajas"},  
    {value:"Lejamaní", label:"Lejamaní"},  
    {value:"Meámbar", label:"Meámbar"},  
    {value:"Minas de Oro", label:"Minas de Oro"},  
    {value:"Ojos de Agua", label:"Ojos de Agua"},  
    {value:"San Jerónimo", label:"San Jerónimo"},  
    {value:"San José de Comayagua", label:"San José de Comayagua"},  
    {value:"San José del Potrero", label:"San José del Potrero"},  
    {value:"San Luis", label:"San Luis"},  
    {value:"San Sebastián", label:"San Sebastián"},  
    {value:"Siguatepeque", label:"Siguatepeque"},  
    {value:"Taulabé", label:"Taulabé"},  
    {value:"Villa de San Antonio", label:"Villa de San Antonio"}
  ],
  "Copán":[
    {value:"Santa Rosa de Copán", label:"Santa Rosa de Copán"},  
    {value:"Cabañas", label:"Cabañas"},  
    {value:"Concepción", label:"Concepción"},  
    {value:"Copán Ruinas", label:"Copán Ruinas"},  
    {value:"Corquín", label:"Corquín"},  
    {value:"Cucuyagua", label:"Cucuyagua"},  
    {value:"Dolores", label:"Dolores"},  
    {value:"Dulce Nombre", label:"Dulce Nombre"},  
    {value:"El Paraíso", label:"El Paraíso"},  
    {value:"Florida", label:"Florida"},  
    {value:"La Jigua", label:"La Jigua"},  
    {value:"La Unión", label:"La Unión"},  
    {value:"Nueva Arcadia", label:"Nueva Arcadia"},  
    {value:"San Agustín", label:"San Agustín"},  
    {value:"San Antonio", label:"San Antonio"},  
    {value:"San Jerónimo", label:"San Jerónimo"},  
    {value:"San José", label:"San José"},  
    {value:"San Juan de Opoa", label:"San Juan de Opoa"},  
    {value:"San Nicolás", label:"San Nicolás"},  
    {value:"San Pedro", label:"San Pedro"},  
    {value:"Santa Rita", label:"Santa Rita"},  
    {value:"Trinidad de Copán", label:"Trinidad de Copán"},  
    {value:"Veracruz", label:"Veracruz"}
  ],
  "Cortés":[
    { value: "Choloma", label: "Choloma" },  
    { value: "La Lima", label: "La Lima" },  
    { value: "San Pedro Sula", label: "San Pedro Sula" },  
    { value: "Villanueva", label: "Villanueva" },  
    { value: "Pimienta", label: "Pimienta" },  
    { value: "Puerto Cortés", label: "Puerto Cortés" },  
    { value: "Santa Cruz de Yojoa", label: "Santa Cruz de Yojoa" },  
    { value: "Potrerillos", label: "Potrerillos" },  
    { value: "Omoa", label: "Omoa" },  
    { value: "San Antonio de Cortés", label: "San Antonio de Cortés" },  
    { value: "Cofradía", label: "Cofradía" },  
    { value: "San Francisco de Yojoa", label: "San Francisco de Yojoa" },  
    { value: "Cuyamel", label: "Cuyamel" },  
    { value: "El Progreso", label: "El Progreso" },  
    { value: "Santa Rita", label: "Santa Rita" },  
    { value: "Pueblo Nuevo", label: "Pueblo Nuevo" },  
    { value: "Quimistán", label: "Quimistán" },  
    { value: "Corquín", label: "Corquín" },  
    { value: "Cucuyagua", label: "Cucuyagua" },  
    { value: "San Juan de Opoa", label: "San Juan de Opoa" },  
    { value: "San Pedro de Tutule", label: "San Pedro de Tutule" },  
    { value: "San Vicente Centenario", label: "San Vicente Centenario" }
  ],
  "El Paraíso":[
    { value: "Danlí", label: "Danlí" },  
    { value: "El Paraíso", label: "El Paraíso" },  
    { value: "Yuscarán", label: "Yuscarán" },  
    { value: "Alauca", label: "Alauca" },  
    { value: "Concepción", label: "Concepción" },  
    { value: "Jacaleapa", label: "Jacaleapa" },  
    { value: "Liure", label: "Liure" },  
    { value: "Morocelí", label: "Morocelí" },  
    { value: "Oropoli", label: "Oropoli" },  
    { value: "Potrerillos", label: "Potrerillos" },  
    { value: "San Antonio de Flores", label: "San Antonio de Flores" },  
    { value: "San Lucas", label: "San Lucas" },  
    { value: "San Matías", label: "San Matías" },  
    { value: "Soledad", label: "Soledad" },  
    { value: "Teupasenti", label: "Teupasenti" },  
    { value: "Texiguat", label: "Texiguat" },  
    { value: "Trojes", label: "Trojes" },  
    { value: "Vado Ancho", label: "Vado Ancho" }
  ],
  "Francisco Morazán":[
    { value: "Distrito Central", label: "Distrito Central" },  
    { value: "Alubaren", label: "Alubaren" },  
    { value: "Cedros", label: "Cedros" },  
    { value: "Curarén", label: "Curarén" },  
    { value: "El Porvenir", label: "El Porvenir" },  
    { value: "Guaimaca", label: "Guaimaca" },  
    { value: "La Libertad", label: "La Libertad" },  
    { value: "La Venta", label: "La Venta" },  
    { value: "Lepaterique", label: "Lepaterique" },  
    { value: "Maraita", label: "Maraita" },  
    { value: "Marale", label: "Marale" },  
    { value: "Nueva Armenia", label: "Nueva Armenia" },  
    { value: "Ojojona", label: "Ojojona" },  
    { value: "Orica", label: "Orica" },  
    { value: "Reitoca", label: "Reitoca" },  
    { value: "Sabanagrande", label: "Sabanagrande" },  
    { value: "San Antonio de Oriente", label: "San Antonio de Oriente" },  
    { value: "San Buenaventura", label: "San Buenaventura" },  
    { value: "San Ignacio", label: "San Ignacio" },  
    { value: "San Juan de Flores", label: "San Juan de Flores" },  
    { value: "San Miguelito", label: "San Miguelito" },  
    { value: "Santa Ana", label: "Santa Ana" },  
    { value: "Santa Lucía", label: "Santa Lucía" },  
    { value: "Talanga", label: "Talanga" },  
    { value: "Tatumbla", label: "Tatumbla" },  
    { value: "Valle de Ángeles", label: "Valle de Ángeles" },  
    { value: "Vallecillo", label: "Vallecillo" }
  ],
  "Gracias a Dios":[
    { value: "Puerto Lempira", label: "Puerto Lempira" },  
    { value: "Brus Laguna", label: "Brus Laguna" },  
    { value: "Juan Francisco Bulnes", label: "Juan Francisco Bulnes" },  
    { value: "Ramón Villeda Morales", label: "Ramón Villeda Morales" },  
    { value: "Wampusirpi", label: "Wampusirpi" },  
    { value: "Ahucapán", label: "Ahucapán" },  
    { value: "Juan Antonio Valle", label: "Juan Antonio Valle" },  
    { value: "Tocamacho", label: "Tocamacho" },  
    { value: "Iriona", label: "Iriona" },  
    { value: "Krautara", label: "Krautara" },  
    { value: "Puerto Lempira (Campo Rojo)", label: "Puerto Lempira (Campo Rojo)" },  
    { value: "Río Esteban", label: "Río Esteban" }
  ],
  "Intibucá":[
    { value: "Camasca", label: "Camasca" },  
    { value: "Colomoncagua", label: "Colomoncagua" },  
    { value: "Concepción", label: "Concepción" },  
    { value: "Dolores", label: "Dolores" },  
    { value: "Intibucá", label: "Intibucá" },  
    { value: "Jesús de Otoro", label: "Jesús de Otoro" },  
    { value: "La Esperanza", label: "La Esperanza" },  
    { value: "Magdalena", label: "Magdalena" },  
    { value: "Masaguara", label: "Masaguara" },  
    { value: "San Antonio", label: "San Antonio" },  
    { value: "San Francisco de Opalaca", label: "San Francisco de Opalaca" },  
    { value: "San Isidro", label: "San Isidro" },  
    { value: "San Juan", label: "San Juan" },  
    { value: "San Marcos de la Sierra", label: "San Marcos de la Sierra" },  
    { value: "San Miguelito", label: "San Miguelito" },  
    { value: "Santa Lucía", label: "Santa Lucía" },  
    { value: "Yamaranguila", label: "Yamaranguila" }
  ],
  "Islas de la Bahía":[
    { value: "Roatán", label: "Roatán" },  
    { value: "Guanaja", label: "Guanaja" },  
    { value: "José Santos Guardiola", label: "José Santos Guardiola" },  
    { value: "Utila", label: "Utila" }
  ],
  "La Paz (Honduras)":[
    { value: "Aguaqueterique", label: "Aguaqueterique" },  
    { value: "Cabañas", label: "Cabañas" },  
    { value: "Cane", label: "Cane" },  
    { value: "Chinacla", label: "Chinacla" },  
    { value: "Guajiquiro", label: "Guajiquiro" },  
    { value: "La Paz", label: "La Paz" },  
    { value: "Lauterique", label: "Lauterique" },  
    { value: "Marcala", label: "Marcala" },  
    { value: "Mercedes de Oriente", label: "Mercedes de Oriente" },  
    { value: "Opatoro", label: "Opatoro" },  
    { value: "San Antonio del Norte", label: "San Antonio del Norte" },  
    { value: "San José", label: "San José" },  
    { value: "San Juan", label: "San Juan" },  
    { value: "San Pedro de Tutule", label: "San Pedro de Tutule" },  
    { value: "Santa Ana", label: "Santa Ana" },  
    { value: "Santa Elena", label: "Santa Elena" },  
    { value: "Santa María", label: "Santa María" },  
    { value: "Santiago de Puringla", label: "Santiago de Puringla" },  
    { value: "Yarula", label: "Yarula" }
  ],
  Lempira:[
    { value: "Gracias", label: "Gracias" },  
    { value: "Belén", label: "Belén" },  
    { value: "Candelaria", label: "Candelaria" },  
    { value: "Cololaca", label: "Cololaca" },  
    { value: "Erandique", label: "Erandique" },  
    { value: "Gualcince", label: "Gualcince" },  
    { value: "Guarita", label: "Guarita" },  
    { value: "La Campa", label: "La Campa" },  
    { value: "La Iguala", label: "La Iguala" },  
    { value: "Las Flores", label: "Las Flores" },  
    { value: "La Unión", label: "La Unión" },  
    { value: "La Virtud", label: "La Virtud" },  
    { value: "Lepaera", label: "Lepaera" },  
    { value: "Mapulaca", label: "Mapulaca" },  
    { value: "Piraera", label: "Piraera" },  
    { value: "San Andrés", label: "San Andrés" },  
    { value: "San Francisco", label: "San Francisco" },  
    { value: "San Juan Guarita", label: "San Juan Guarita" },  
    { value: "San Manuel Colohete", label: "San Manuel Colohete" },  
    { value: "San Marcos de Caiquín", label: "San Marcos de Caiquín" },  
    { value: "San Rafael", label: "San Rafael" },  
    { value: "San Sebastián", label: "San Sebastián" },  
    { value: "Santa Cruz", label: "Santa Cruz" },  
    { value: "Talgua", label: "Talgua" },  
    { value: "Tambla", label: "Tambla" },  
    { value: "Tomalá", label: "Tomalá" },  
    { value: "Valladolid", label: "Valladolid" },  
    { value: "Virginia", label: "Virginia" }
  ],
  Ocotepeque:[
    { value: "Belén Gualcho", label: "Belén Gualcho" },  
    { value: "Concepción", label: "Concepción" },  
    { value: "Dolores Merendon", label: "Dolores Merendon" },  
    { value: "Fraternidad", label: "Fraternidad" },  
    { value: "La Encarnación", label: "La Encarnación" },  
    { value: "La Labor", label: "La Labor" },  
    { value: "Lucerna", label: "Lucerna" },  
    { value: "Mercedes", label: "Mercedes" },  
    { value: "Ocotepeque", label: "Ocotepeque" },  
    { value: "San Fernando", label: "San Fernando" },  
    { value: "San Francisco del Valle", label: "San Francisco del Valle" },  
    { value: "San Jorge", label: "San Jorge" },  
    { value: "San Marcos", label: "San Marcos" },  
    { value: "Santa Fe", label: "Santa Fe" },  
    { value: "Sensenti", label: "Sensenti" },  
    { value: "Sinuapa", label: "Sinuapa" }
  ],
  Olancho:[
    { value: "Campamento", label: "Campamento" },  
    { value: "Catacamas", label: "Catacamas" },  
    { value: "Concordia", label: "Concordia" },  
    { value: "Dulce Nombre de Culmí", label: "Dulce Nombre de Culmí" },  
    { value: "El Rosario", label: "El Rosario" },  
    { value: "Esquipulas del Norte", label: "Esquipulas del Norte" },  
    { value: "Gualaco", label: "Gualaco" },  
    { value: "Guarizama", label: "Guarizama" },  
    { value: "Guata", label: "Guata" },  
    { value: "Jano", label: "Jano" },  
    { value: "Juticalpa", label: "Juticalpa" },  
    { value: "La Unión", label: "La Unión" },  
    { value: "Lepaguare", label: "Lepaguare" },  
    { value: "Magdalena", label: "Magdalena" },  
    { value: "Mangulile", label: "Mangulile" },  
    { value: "Manto", label: "Manto" },  
    { value: "Municipio de Patuca", label: "Municipio de Patuca" },  
    { value: "Municipio de Santa María del Real", label: "Municipio de Santa María del Real" },  
    { value: "Olanchito", label: "Olanchito" },  
    { value: "San Esteban", label: "San Esteban" },  
    { value: "San Francisco de Becerra", label: "San Francisco de Becerra" },  
    { value: "San Francisco de La Paz", label: "San Francisco de La Paz" },  
    { value: "Santa Maria del Real", label: "Santa Maria del Real" },  
    { value: "Silca", label: "Silca" },  { value: "Yocón", label: "Yocón" }
  ],
  "Santa Bárbara":[
    { value: "Arada", label: "Arada" },  
    { value: "Atima", label: "Atima" },  
    { value: "Azacualpa", label: "Azacualpa" },  
    { value: "Ceguaca", label: "Ceguaca" },  
    { value: "Chinda", label: "Chinda" },  
    { value: "Concepción del Norte", label: "Concepción del Norte" },  
    { value: "Concepción del Sur", label: "Concepción del Sur" },  
    { value: "El Níspero", label: "El Níspero" },  
    { value: "Gualala", label: "Gualala" },  
    { value: "Ilama", label: "Ilama" },  
    { value: "Las Vegas", label: "Las Vegas" },  
    { value: "Macuelizo", label: "Macuelizo" },  
    { value: "Naranjito", label: "Naranjito" },  
    { value: "Nuevo Celilac", label: "Nuevo Celilac" },  
    { value: "Petoa", label: "Petoa" },  
    { value: "Protección", label: "Protección" },  
    { value: "Quimistán", label: "Quimistán" },  
    { value: "San Francisco de Ojuera", label: "San Francisco de Ojuera" },  
    { value: "San José de Colinas", label: "San José de Colinas" },  
    { value: "San Luis", label: "San Luis" },  
    { value: "San Marcos", label: "San Marcos" },  
    { value: "San Nicolás", label: "San Nicolás" },  
    { value: "San Pedro Zacapa", label: "San Pedro Zacapa" },  
    { value: "Santa Bárbara", label: "Santa Bárbara" },  
    { value: "Santa Rita", label: "Santa Rita" },  
    { value: "Trinidad", label: "Trinidad" }
  ],
  Valle:[
    { value: "Alianza", label: "Alianza" },  
    { value: "Amapala", label: "Amapala" },  
    { value: "Aramecina", label: "Aramecina" },  
    { value: "Caridad", label: "Caridad" },  
    { value: "Goascorán", label: "Goascorán" },  
    { value: "Langue", label: "Langue" },  
    { value: "Nacaome", label: "Nacaome" },  
    { value: "San Francisco de Coray", label: "San Francisco de Coray" },  
    { value: "San Lorenzo", label: "San Lorenzo" }
  ],
  "Yoro":[
    { value: "Arenal", label: "Arenal" },  
    { value: "El Negrito", label: "El Negrito" },  
    { value: "El Progreso", label: "El Progreso" },  
    { value: "Jocón", label: "Jocón" },  
    { value: "Morazán", label: "Morazán" },  
    { value: "Olanchito", label: "Olanchito" },  
    { value: "Santa Rita", label: "Santa Rita" },  
    { value: "Sulaco", label: "Sulaco" },  
    { value: "Victoria", label: "Victoria" },  
    { value: "Yorito", label: "Yorito" }
  ],
  //EL SALVADOR
  "Ahuachapán":[
    { value: "Ahuachapán", label: "Ahuachapán" },  
    { value: "Apaneca", label: "Apaneca" },  
    { value: "Atiquizaya", label: "Atiquizaya" },  
    { value: "Concepción de Ataco", label: "Concepción de Ataco" },  
    { value: "El Refugio", label: "El Refugio" },  
    { value: "Guaymango", label: "Guaymango" },  
    { value: "Jujutla", label: "Jujutla" },  
    { value: "San Francisco Menéndez", label: "San Francisco Menéndez" },  
    { value: "San Lorenzo", label: "San Lorenzo" },  
    { value: "San Pedro Puxtla", label: "San Pedro Puxtla" },  
    { value: "Tacuba", label: "Tacuba" },  
    { value: "Turín", label: "Turín" }
  ],
  "Cabañas":[
    { value: "Cinquera", label: "Cinquera" },  
    { value: "Dolores", label: "Dolores" },  
    { value: "Guacotecti", label: "Guacotecti" },  
    { value: "Ilobasco", label: "Ilobasco" },  
    { value: "Jutiapa", label: "Jutiapa" },  
    { value: "San Isidro", label: "San Isidro" },  
    { value: "Sensuntepeque", label: "Sensuntepeque" },  
    { value: "Tejutepeque", label: "Tejutepeque" },  
    { value: "Victoria", label: "Victoria" }
  ],
  Chalatenango:[
    { value: "Agua Caliente", label: "Agua Caliente" },  
    { value: "Arcatao", label: "Arcatao" },  
    { value: "Azacualpa", label: "Azacualpa" },  
    { value: "Chalatenango", label: "Chalatenango" },  
    { value: "Citalá", label: "Citalá" },  
    { value: "Comalapa", label: "Comalapa" },  
    { value: "Concepción Quezaltepeque", label: "Concepción Quezaltepeque" },  
    { value: "Dulce Nombre de María", label: "Dulce Nombre de María" },  
    { value: "El Carrizal", label: "El Carrizal" },  
    { value: "El Paraíso", label: "El Paraíso" },  
    { value: "La Laguna", label: "La Laguna" },  
    { value: "La Palma", label: "La Palma" },  
    { value: "La Reina", label: "La Reina" },  
    { value: "Las Flores", label: "Las Flores" },  
    { value: "Las Vueltas", label: "Las Vueltas" },  
    { value: "Nombre de Jesús", label: "Nombre de Jesús" },  
    { value: "Nueva Concepción", label: "Nueva Concepción" },  
    { value: "Nueva Trinidad", label: "Nueva Trinidad" },  
    { value: "Ojos de Agua", label: "Ojos de Agua" },  
    { value: "Potonico", label: "Potonico" },  
    { value: "San Antonio de la Cruz", label: "San Antonio de la Cruz" },  
    { value: "San Antonio Los Ranchos", label: "San Antonio Los Ranchos" },  
    { value: "San Fernando", label: "San Fernando" },  
    { value: "San Francisco Lempa", label: "San Francisco Lempa" },  
    { value: "San Francisco Morazán", label: "San Francisco Morazán" },  
    { value: "San Ignacio", label: "San Ignacio" },  
    { value: "San Isidro Labrador", label: "San Isidro Labrador" },  
    { value: "San José Cancasque", label: "San José Cancasque" },  
    { value: "San José Las Flores", label: "San José Las Flores" },  
    { value: "San Luis del Carmen", label: "San Luis del Carmen" },  
    { value: "San Miguel de Mercedes", label: "San Miguel de Mercedes" },  
    { value: "San Rafael", label: "San Rafael" },  
    { value: "Santa Rita", label: "Santa Rita" },  
    { value: "Tejutla", label: "Tejutla" }
  ],
  "Cuscatlán":[
    { value: "Candelaria", label: "Candelaria" },  
    { value: "Cojutepeque", label: "Cojutepeque" },  
    { value: "El Carmen", label: "El Carmen" },  
    { value: "El Rosario", label: "El Rosario" },  
    { value: "Monte San Juan", label: "Monte San Juan" },  
    { value: "Oratorio de Concepción", label: "Oratorio de Concepción" },  
    { value: "San Bartolomé Perulapía", label: "San Bartolomé Perulapía" },  
    { value: "San Cristóbal", label: "San Cristóbal" },  
    { value: "San José Guayabal", label: "San José Guayabal" },  
    { value: "San Pedro Perulapán", label: "San Pedro Perulapán" },  
    { value: "San Rafael Cedros", label: "San Rafael Cedros" },  
    { value: "San Ramón", label: "San Ramón" },  
    { value: "Santa Cruz Analquito", label: "Santa Cruz Analquito" },  
    { value: "Santa Cruz Michapa", label: "Santa Cruz Michapa" },  
    { value: "Suchitoto", label: "Suchitoto" }
  ],
  "La Libertad":[
    { value: "Antiguo Cuscatlán", label: "Antiguo Cuscatlán" },  
    { value: "Chiltiupán", label: "Chiltiupán" },  
    { value: "Ciudad Arce", label: "Ciudad Arce" },  
    { value: "Colón", label: "Colón" },  
    { value: "Comasagua", label: "Comasagua" },  
    { value: "Huizúcar", label: "Huizúcar" },  
    { value: "Jayaque", label: "Jayaque" },  
    { value: "Jicalapa", label: "Jicalapa" },  
    { value: "La Libertad", label: "La Libertad" },  
    { value: "Nuevo Cuscatlán", label: "Nuevo Cuscatlán" },  
    { value: "Opico", label: "Opico" },  
    { value: "Quezaltepeque", label: "Quezaltepeque" },  
    { value: "Sacacoyo", label: "Sacacoyo" },  
    { value: "San José Villanueva", label: "San José Villanueva" },  
    { value: "San Juan Opico", label: "San Juan Opico" },  
    { value: "San Matías", label: "San Matías" },  
    { value: "San Pablo Tacachico", label: "San Pablo Tacachico" },  
    { value: "Tamanique", label: "Tamanique" },  
    { value: "Tecoluca", label: "Tecoluca" },  
    { value: "Teotepeque", label: "Teotepeque" },  
    { value: "Tepecoyo", label: "Tepecoyo" },  
    { value: "Zaragoza", label: "Zaragoza" }
  ],
  "La Paz":[
    { value: "Cuyultitán", label: "Cuyultitán" },  
    { value: "El Rosario", label: "El Rosario" },  
    { value: "Jerusalén", label: "Jerusalén" },  
    { value: "Mercedes La Ceiba", label: "Mercedes La Ceiba" },  
    { value: "Olocuilta", label: "Olocuilta" },  
    { value: "Paraíso de Osorio", label: "Paraíso de Osorio" },  
    { value: "San Antonio Masahuat", label: "San Antonio Masahuat" },  
    { value: "San Emigdio", label: "San Emigdio" },  
    { value: "San Francisco Chinameca", label: "San Francisco Chinameca" },  
    { value: "San Juan Nonualco", label: "San Juan Nonualco" },  
    { value: "San Juan Talpa", label: "San Juan Talpa" },  
    { value: "San Juan Tepezontes", label: "San Juan Tepezontes" },  
    { value: "San Luis La Herradura", label: "San Luis La Herradura" },  
    { value: "San Luis Talpa", label: "San Luis Talpa" },  
    { value: "San Miguel Tepezontes", label: "San Miguel Tepezontes" },  
    { value: "San Pedro Masahuat", label: "San Pedro Masahuat" },  
    { value: "San Pedro Nonualco", label: "San Pedro Nonualco" },  
    { value: "San Rafael Obrajuelo", label: "San Rafael Obrajuelo" },  
    { value: "Santa María Ostuma", label: "Santa María Ostuma" },  
    { value: "Santiago Nonualco", label: "Santiago Nonualco" },  
    { value: "Tapalhuaca", label: "Tapalhuaca" },  
    { value: "Zacatecoluca", label: "Zacatecoluca" }
  ],
  "La Unión":[
    { value: "Anamorós", label: "Anamorós" },  
    { value: "Bolívar", label: "Bolívar" },  
    { value: "Concepción de Oriente", label: "Concepción de Oriente" },  
    { value: "Conchagua", label: "Conchagua" },  
    { value: "El Carmen", label: "El Carmen" },  
    { value: "El Sauce", label: "El Sauce" },  
    { value: "Intipucá", label: "Intipucá" },  
    { value: "La Unión", label: "La Unión" },  
    { value: "Lislique", label: "Lislique" },  
    { value: "Meanguera del Golfo", label: "Meanguera del Golfo" },  
    { value: "Nueva Esparta", label: "Nueva Esparta" },  
    { value: "Pasaquina", label: "Pasaquina" },  
    { value: "Polorós", label: "Polorós" },  
    { value: "San Alejo", label: "San Alejo" },  
    { value: "San José", label: "San José" },  
    { value: "Santa Rosa de Lima", label: "Santa Rosa de Lima" },  
    { value: "Yayantique", label: "Yayantique" },  
    { value: "Yucuaiquín", label: "Yucuaiquín" }
  ],
  "Morazán":[
    { value: "Arambala", label: "Arambala" },  
    { value: "Cacaopera", label: "Cacaopera" },  
    { value: "Chilanga", label: "Chilanga" },  
    { value: "Corinto", label: "Corinto" },  
    { value: "Delicias de Concepción", label: "Delicias de Concepción" },  
    { value: "El Divisadero", label: "El Divisadero" },  
    { value: "El Rosario", label: "El Rosario" },  
    { value: "Gualococti", label: "Gualococti" },  
    { value: "Guatajiagua", label: "Guatajiagua" },  
    { value: "Joateca", label: "Joateca" },  
    { value: "Jocoaitique", label: "Jocoaitique" },  
    { value: "Jocoro", label: "Jocoro" },  
    { value: "Lolotique", label: "Lolotique" },  
    { value: "Meanguera", label: "Meanguera" },  
    { value: "Osicala", label: "Osicala" },  
    { value: "Perquín", label: "Perquín" },  
    { value: "San Carlos", label: "San Carlos" },  
    { value: "San Fernando", label: "San Fernando" },  
    { value: "San Francisco Gotera", label: "San Francisco Gotera" },  
    { value: "San Isidro", label: "San Isidro" },  
    { value: "San Simón", label: "San Simón" },  
    { value: "Sensembra", label: "Sensembra" },  
    { value: "Sociedad", label: "Sociedad" },  
    { value: "Torola", label: "Torola" },  
    { value: "Yamabal", label: "Yamabal" }
  ],
  "San Miguel":[
    { value: "Carolina", label: "Carolina" },  
    { value: "Chapeltique", label: "Chapeltique" },  
    { value: "Chinameca", label: "Chinameca" },  
    { value: "Chirilagua", label: "Chirilagua" },  
    { value: "Ciudad Barrios", label: "Ciudad Barrios" },  
    { value: "Comacarán", label: "Comacarán" },  
    { value: "El Tránsito", label: "El Tránsito" },  
    { value: "Lolotique", label: "Lolotique" },  
    { value: "Moncagua", label: "Moncagua" },  
    { value: "Nueva Guadalupe", label: "Nueva Guadalupe" },  
    { value: "Nuevo Edén de San Juan", label: "Nuevo Edén de San Juan" },  
    { value: "Quelepa", label: "Quelepa" },  
    { value: "San Antonio", label: "San Antonio" },  
    { value: "San Gerardo", label: "San Gerardo" },  
    { value: "San Jorge", label: "San Jorge" },  
    { value: "San Luis de la Reina", label: "San Luis de la Reina" },  
    { value: "San Miguel", label: "San Miguel" },  
    { value: "San Rafael Oriente", label: "San Rafael Oriente" },  
    { value: "Sesori", label: "Sesori" },  
    { value: "Uluazapa", label: "Uluazapa" }
  ],
  "San Salvador":[
    { value: "Ayutuxtepeque", label: "Ayutuxtepeque" },  
    { value: "Cuscatancingo", label: "Cuscatancingo" },  
    { value: "Ciudad Delgado", label: "Ciudad Delgado" },  
    { value: "El Paisnal", label: "El Paisnal" },  
    { value: "Guazapa", label: "Guazapa" },  
    { value: "Ilopango", label: "Ilopango" },  
    { value: "Mejicanos", label: "Mejicanos" },  
    { value: "Nejapa", label: "Nejapa" },  
    { value: "Panchimalco", label: "Panchimalco" },  
    { value: "Rosario de Mora", label: "Rosario de Mora" },  
    { value: "San Marcos", label: "San Marcos" },  
    { value: "San Martín", label: "San Martín" },  
    { value: "San Salvador", label: "San Salvador" },  
    { value: "Santiago Texacuangos", label: "Santiago Texacuangos" },  
    { value: "Santo Tomás", label: "Santo Tomás" },  
    { value: "Soyapango", label: "Soyapango" },  
    { value: "Tonacatepeque", label: "Tonacatepeque" }
  ],
  "Santa Ana":[
    { value: "Candelaria de la Frontera", label: "Candelaria de la Frontera" },  
    { value: "Chalchuapa", label: "Chalchuapa" },  
    { value: "Coatepeque", label: "Coatepeque" },  
    { value: "El Congo", label: "El Congo" },  
    { value: "El Porvenir", label: "El Porvenir" },  
    { value: "Masahuat", label: "Masahuat" },  
    { value: "Metapán", label: "Metapán" },  
    { value: "San Antonio Pajonal", label: "San Antonio Pajonal" },  
    { value: "San Sebastián Salitrillo", label: "San Sebastián Salitrillo" },  
    { value: "Santa Ana", label: "Santa Ana" },  
    { value: "Santa Rosa Guachipilín", label: "Santa Rosa Guachipilín" },  
    { value: "Santiago de la Frontera", label: "Santiago de la Frontera" },  
    { value: "Texistepeque", label: "Texistepeque" }
  ],
  "San Vicente":[
    { value: "Apastepeque", label: "Apastepeque" },  
    { value: "Guadalupe", label: "Guadalupe" },  
    { value: "San Cayetano Istepeque", label: "San Cayetano Istepeque" },  
    { value: "San Esteban Catarina", label: "San Esteban Catarina" },  
    { value: "San Ildefonso", label: "San Ildefonso" },  
    { value: "San Lorenzo", label: "San Lorenzo" },  
    { value: "San Sebastián", label: "San Sebastián" },  
    { value: "San Vicente", label: "San Vicente" },  
    { value: "Santa Clara", label: "Santa Clara" },  
    { value: "Santo Domingo", label: "Santo Domingo" },  
    { value: "Tecoluca", label: "Tecoluca" },  
    { value: "Tepetitán", label: "Tepetitán" },  
    { value: "Verapaz", label: "Verapaz" }
  ],
  Sonsonate:[
    { value: "Acajutla", label: "Acajutla" },  
    { value: "Armenia", label: "Armenia" },  
    { value: "Caluco", label: "Caluco" },  
    { value: "Cuisnahuat", label: "Cuisnahuat" },  
    { value: "Izalco", label: "Izalco" },  
    { value: "Juayúa", label: "Juayúa" },  
    { value: "Nahuizalco", label: "Nahuizalco" },  
    { value: "Nahulingo", label: "Nahulingo" },  
    { value: "Salcoatitán", label: "Salcoatitán" },  
    { value: "San Antonio del Monte", label: "San Antonio del Monte" },  
    { value: "San Julián", label: "San Julián" },  
    { value: "Santa Catarina Masahuat", label: "Santa Catarina Masahuat" },  
    { value: "Santa Isabel Ishuatán", label: "Santa Isabel Ishuatán" },  
    { value: "Santo Domingo de Guzmán", label: "Santo Domingo de Guzmán" },  
    { value: "Sonsonate", label: "Sonsonate" }
  ],
  "Usulután":[
    { value: "Alegría", label: "Alegría" },  
    { value: "Berlín", label: "Berlín" },  
    { value: "California", label: "California" },  
    { value: "Concepción Batres", label: "Concepción Batres" },  
    { value: "El Triunfo", label: "El Triunfo" },  
    { value: "Ereguayquín", label: "Ereguayquín" },  
    { value: "Estanzuelas", label: "Estanzuelas" },  
    { value: "Jiquilisco", label: "Jiquilisco" },  
    { value: "Jucuapa", label: "Jucuapa" },  
    { value: "Jucuarán", label: "Jucuarán" },  
    { value: "Mercedes Umaña", label: "Mercedes Umaña" },  
    { value: "Nueva Granada", label: "Nueva Granada" },  
    { value: "Ozatlán", label: "Ozatlán" },  
    { value: "Puerto El Triunfo", label: "Puerto El Triunfo" },  
    { value: "San Agustín", label: "San Agustín" },  
    { value: "San Buenaventura", label: "San Buenaventura" },  
    { value: "San Dionisio", label: "San Dionisio" },  
    { value: "San Francisco Javier", label: "San Francisco Javier" },  
    { value: "Santa Elena", label: "Santa Elena" },  
    { value: "Santa María", label: "Santa María" },  
    { value: "Santiago de María", label: "Santiago de María" },  
    { value: "Tecapán", label: "Tecapán" },  
    { value: "Usulután", label: "Usulután" }
  ],
  //NICARAGUA
  Boaco:[
    { value: "Boaco", label: "Boaco" },  
    { value: "Camoapa", label: "Camoapa" },  
    { value: "San José de los Remates", label: "San José de los Remates" },  
    { value: "San Lorenzo", label: "San Lorenzo" },  
    { value: "Santa Lucía", label: "Santa Lucía" },  
    { value: "Teustepe", label: "Teustepe" }
  ],
  Carazo:[
    { value: "Jinotepe", label: "Jinotepe" },  
    { value: "Diriamba", label: "Diriamba" },  
    { value: "San Marcos", label: "San Marcos" },  
    { value: "Dolores", label: "Dolores" },  
    { value: "El Rosario", label: "El Rosario" },  
    { value: "La Conquista", label: "La Conquista" },  
    { value: "La Paz de Oriente", label: "La Paz de Oriente" },  
    { value: "San Rafael del Sur", label: "San Rafael del Sur" }
  ],
  Chinandega:[
    { value: "Chinandega", label: "Chinandega" },  
    { value: "Corinto", label: "Corinto" },  
    { value: "El Realejo", label: "El Realejo" },  
    { value: "Chichigalpa", label: "Chichigalpa" },  
    { value: "Posoltega", label: "Posoltega" },  
    { value: "El Viejo", label: "El Viejo" },  
    { value: "Puerto Morazán", label: "Puerto Morazán" },  
    { value: "Somotillo", label: "Somotillo" },  
    { value: "Villanueva", label: "Villanueva" },  
    { value: "San Francisco del Norte", label: "San Francisco del Norte" },  
    { value: "Cinco Pinos", label: "Cinco Pinos" }
  ],
  Chontales:[
    { value: "Juigalpa", label: "Juigalpa" },  
    { value: "Acoyapa", label: "Acoyapa" },  
    { value: "Santo Tomás", label: "Santo Tomás" },  
    { value: "Comalapa", label: "Comalapa" },  
    { value: "La Libertad", label: "La Libertad" },  
    { value: "Villa Sandino", label: "Villa Sandino" },  
    { value: "El Coral", label: "El Coral" },  
    { value: "San Pedro de Lóvago", label: "San Pedro de Lóvago" },  
    { value: "Santo Domingo", label: "Santo Domingo" },  
    { value: "Teustepe", label: "Teustepe" },  
    { value: "San Francisco de Cuapa", label: "San Francisco de Cuapa" }
  ],
  "Estelí":[
    { value: "Estelí", label: "Estelí" },  
    { value: "Condega", label: "Condega" },  
    { value: "Pueblo Nuevo", label: "Pueblo Nuevo" },  
    { value: "San Juan de Limay", label: "San Juan de Limay" },  
    { value: "La Trinidad", label: "La Trinidad" }
  ],
  Granada:[
    { value: "Granada", label: "Granada" },  
    { value: "Diriá", label: "Diriá" },  
    { value: "Diriomo", label: "Diriomo" },  
    { value: "Nandaime", label: "Nandaime" },  
    { value: "Dolores", label: "Dolores" },  
    { value: "Diriangen", label: "Diriangen" },  
    { value: "Laguna de Perlas", label: "Laguna de Perlas" }
  ],
  Jinotega:[
    { value: "Jinotega", label: "Jinotega" },  
    { value: "La Concordia", label: "La Concordia" },  
    { value: "San José de Bocay", label: "San José de Bocay" },  
    { value: "San Rafael del Norte", label: "San Rafael del Norte" },  
    { value: "San Sebastián de Yalí", label: "San Sebastián de Yalí" },  
    { value: "Santa María de Pantasma", label: "Santa María de Pantasma" },  
    { value: "El Cuá", label: "El Cuá" },  
    { value: "San Rafael del Sur", label: "San Rafael del Sur" }
  ],
  "León":[
    { value: "León", label: "León" },  
    { value: "La Paz Centro", label: "La Paz Centro" },  
    { value: "El Jicaral", label: "El Jicaral" },  
    { value: "El Sauce", label: "El Sauce" },  
    { value: "Quezalguaque", label: "Quezalguaque" },  
    { value: "Santa Rosa del Peñón", label: "Santa Rosa del Peñón" },  
    { value: "Larreynaga", label: "Larreynaga" }
  ],
  Madriz:[
    { value: "Somoto", label: "Somoto" },  
    { value: "San Lucas", label: "San Lucas" },  
    { value: "Telpaneca", label: "Telpaneca" },  
    { value: "Palacagüina", label: "Palacagüina" },  
    { value: "Yalagüina", label: "Yalagüina" },  
    { value: "San Juan del Río Coco", label: "San Juan del Río Coco" },  
    { value: "Las Sabanas", label: "Las Sabanas" }
  ],
  Managua:[
    { value: "Managua", label: "Managua" },  
    { value: "Ciudad Sandino", label: "Ciudad Sandino" },  
    { value: "El Crucero", label: "El Crucero" },  
    { value: "Mateare", label: "Mateare" },  
    { value: "San Francisco Libre", label: "San Francisco Libre" },  
    { value: "San Rafael del Sur", label: "San Rafael del Sur" },  
    { value: "Ticuantepe", label: "Ticuantepe" },  
    { value: "Tipitapa", label: "Tipitapa" },  
    { value: "Villa Carlos Fonseca", label: "Villa Carlos Fonseca" }
  ],
  Masaya:[
    { value: "Masaya", label: "Masaya" },  
    { value: "Catarina", label: "Catarina" },  
    { value: "La Concepción", label: "La Concepción" },  
    { value: "Masatepe", label: "Masatepe" },  
    { value: "Nandasmo", label: "Nandasmo" },  
    { value: "Nindirí", label: "Nindirí" },  
    { value: "Niquinohomo", label: "Niquinohomo" },  
    { value: "San Juan de Oriente", label: "San Juan de Oriente" },  
    { value: "Tisma", label: "Tisma" }
  ],
  Matagalpa:[
    { value: "Matagalpa", label: "Matagalpa" },  
    { value: "Ciudad Darío", label: "Ciudad Darío" },  
    { value: "Esquipulas", label: "Esquipulas" },  
    { value: "Matiguás", label: "Matiguás" },  
    { value: "Muy Muy", label: "Muy Muy" },  
    { value: "Rancho Grande", label: "Rancho Grande" },  
    { value: "San Dionisio", label: "San Dionisio" },  
    { value: "San Isidro", label: "San Isidro" },  
    { value: "San Ramón", label: "San Ramón" },  
    { value: "Sébaco", label: "Sébaco" },  
    { value: "Terrabona", label: "Terrabona" }
  ],
  "Nueva Segovia":[
    { value: "Ocotal", label: "Ocotal" },  
    { value: "Ciudad Antigua", label: "Ciudad Antigua" },  
    { value: "Dipilto", label: "Dipilto" },  
    { value: "El Jícaro", label: "El Jícaro" },  
    { value: "Jalapa", label: "Jalapa" },  
    { value: "Macuelizo", label: "Macuelizo" },  
    { value: "Mozonte", label: "Mozonte" },  
    { value: "Murra", label: "Murra" },  
    { value: "Nueva Segovia", label: "Nueva Segovia" },  
    { value: "Quilalí", label: "Quilalí" },  
    { value: "San Fernando", label: "San Fernando" },  
    { value: "Santa María", label: "Santa María" },  
    { value: "Wiwilí", label: "Wiwilí" }
  ],
  Rivas:[
    { value: "Altagracia", label: "Altagracia" },  
    { value: "Belén", label: "Belén" },  
    { value: "Buenos Aires", label: "Buenos Aires" },  
    { value: "Cárdenas", label: "Cárdenas" },  
    { value: "San Jorge", label: "San Jorge" },  
    { value: "Potosí", label: "Potosí" },  
    { value: "Rivas", label: "Rivas" }
  ],
  "Río San Juan":[
    { value: "El Castillo", label: "El Castillo" },  
    { value: "Morrito", label: "Morrito" },  
    { value: "San Carlos", label: "San Carlos" },  
    { value: "San Juan del Norte", label: "San Juan del Norte" }
  ],
  //COSTA RICA
  "San José":[
    { value: "San José", label: "San José" },  
    { value: "Escazú", label: "Escazú" },  
    { value: "Desamparados", label: "Desamparados" },  
    { value: "Puriscal", label: "Puriscal" },  
    { value: "Tarrazú", label: "Tarrazú" },  
    { value: "Aserrí", label: "Aserrí" },  
    { value: "Mora", label: "Mora" },  
    { value: "Goicoechea", label: "Goicoechea" },  
    { value: "Santa Ana", label: "Santa Ana" },  
    { value: "Alajuelita", label: "Alajuelita" },  
    { value: "Vázquez de Coronado", label: "Vázquez de Coronado" },  
    { value: "Acosta", label: "Acosta" },  
    { value: "Tibás", label: "Tibás" },  
    { value: "Moravia", label: "Moravia" },  
    { value: "Montes de Oca", label: "Montes de Oca" },  
    { value: "Turrubares", label: "Turrubares" },  
    { value: "Dota", label: "Dota" },  
    { value: "Curridabat", label: "Curridabat" },  
    { value: "Pérez Zeledón", label: "Pérez Zeledón" },  
    { value: "León Cortés", label: "León Cortés" }
  ],
  Alajuela:[
    { value: "Alajuela", label: "Alajuela" },  
    { value: "San Rafael", label: "San Rafael" },  
    { value: "San Antonio", label: "San Antonio" },  
    { value: "La Guácima", label: "La Guácima" },  
    { value: "Río Segundo", label: "Río Segundo" },  
    { value: "Desamparados", label: "Desamparados" },  
    { value: "Turrúcares", label: "Turrúcares" },  
    { value: "Tambor", label: "Tambor" },  
    { value: "La Garita", label: "La Garita" },  
    { value: "Sabanilla", label: "Sabanilla" },  
    { value: "San Isidro", label: "San Isidro" },  
    { value: "San José", label: "San José" },  
    { value: "Carrizal", label: "Carrizal" },  
    { value: "San Juan", label: "San Juan" },  
    { value: "San Pedro", label: "San Pedro" },  
    { value: "Sarapiquí", label: "Sarapiquí" },  
    { value: "Turrujal", label: "Turrujal" },  
    { value: "Rio Cuarto", label: "Rio Cuarto" }
  ],
  Cartago:[
    {value:"Alvarado", label:"Alvarado"}, 
    {value:"Cartago", label:"Cartago"}, 
    {value:"El Guarco", label:"El Guarco"}, 
    {value:"Jiménez", label:"Jiménez"}, 
    {value:"La Unión", label:"La Unión"}, 
    {value:"Oreamuno", label:"Oreamuno"}, 
    {value:"Paraíso", label:"Paraíso"}, 
    {value:"Turrialba", label:"Turrialba"}
  ],
  Heredia:[
    {value: "Heredia", label: "Heredia"},  
    {value: "Barva", label: "Barva"},  
    {value: "Belen", label: "Belen"},  
    {value: "Flores", label: "Flores"},  
    {value: "San Isidro", label: "San Isidro"},  
    {value: "San Pablo", label: "San Pablo"},  
    {value: "San Rafael", label: "San Rafael"},  
    {value: "San Jose de la Montaña", label: "San Jose de la Montaña"}
  ],
  Guanacaste:[
    {value: "Liberia", label: "Liberia"},  
    {value: "Nicoya", label: "Nicoya"},  
    {value: "Santa Cruz", label: "Santa Cruz"},  
    {value: "Bagaces", label: "Bagaces"},  
    {value: "Carrillo", label: "Carrillo"},  
    {value: "Cañas", label: "Cañas"},  
    {value: "Abangares", label: "Abangares"},  
    {value: "Tilarán", label: "Tilarán"},  
    {value: "Nandayure", label: "Nandayure"},  
    {value: "La Cruz", label: "La Cruz"},  
    {value: "Hojancha", label: "Hojancha"}
  ],
  Puntarenas:[
    {value: "Puntarenas", label: "Puntarenas"},    
    {value: "Aguirre", label: "Aguirre"},    
    {value: "Buenos Aires", label: "Buenos Aires"},    
    {value: "Corredores", label: "Corredores"},    
    {value: "Coto Brus", label: "Coto Brus"},    
    {value: "Esparza", label: "Esparza"},    
    {value: "Garabito", label: "Garabito"},    
    {value: "Golfito", label: "Golfito"},    
    {value: "Montes de Oro", label: "Montes de Oro"},    
    {value: "Osa", label: "Osa"},    
    {value: "Parrita", label: "Parrita"}
  ],
  "Limón":[
    { value: "Limón", label: "Limón" },  
    { value: "Pococí", label: "Pococí" },  
    { value: "Siquirres", label: "Siquirres" },  
    { value: "Talamanca", label: "Talamanca" },  
    { value: "Matina", label: "Matina" },  
    { value: "Guácimo", label: "Guácimo" }
  ],
  //PANAMA
  "Bocas del Toro":[
    { value: "Bocas del Toro", label: "Bocas del Toro" },  
    { value: "Changuinola", label: "Changuinola" },  
    { value: "Almirante", label: "Almirante" },  
    { value: "Chiriquí Grande", label: "Chiriquí Grande" },  
    { value: "Bocas del Toro", label: "Bocas del Toro" },  
    { value: "Tierras Altas", label: "Tierras Altas" },  
    { value: "Talamanca", label: "Talamanca" },  
    { value: "Barú", label: "Barú" },  
    { value: "Boquete", label: "Boquete" },  
    { value: "Dolega", label: "Dolega" },  
    { value: "Gualaca", label: "Gualaca" },  
    { value: "Bugaba", label: "Bugaba" },  
    { value: "Remedios", label: "Remedios" },  
    { value: "San Lorenzo", label: "San Lorenzo" },  
    { value: "San Félix", label: "San Félix" },  
    { value: "Renacimiento", label: "Renacimiento" },  
    { value: "Alanje", label: "Alanje" },  
    { value: "Boquerón", label: "Boquerón" },  
    { value: "Dolega", label: "Dolega" },  
    { value: "Gualaca", label: "Gualaca" },  
    { value: "La Concepción", label: "La Concepción" },  
    { value: "Ojo de Agua", label: "Ojo de Agua" },  
    { value: "Volcán", label: "Volcán" }
  ],
  "Coclé":[
    { value: "Aguadulce", label: "Aguadulce" },    
    { value: "Antón", label: "Antón" },    
    { value: "La Pintada", label: "La Pintada" },    
    { value: "Natá", label: "Natá" },    
    { value: "Olá", label: "Olá" },    
    { value: "Penonomé", label: "Penonomé" },    
    { value: "Pocrí", label: "Pocrí" },    
    { value: "Río Hato", label: "Río Hato" }
  ],
  "Colón (Panamá)":[
    { value: "Colón", label: "Colón" },  
    { value: "Chagres", label: "Chagres" },  
    { value: "Donoso", label: "Donoso" },  
    { value: "Portobelo", label: "Portobelo" }
  ],
  "Chiriquí":[
    { value: "Alanje", label: "Alanje" },  
    { value: "Barú", label: "Barú" },  
    { value: "Boquerón", label: "Boquerón" },  
    { value: "Boquete", label: "Boquete" },  
    { value: "Bugaba", label: "Bugaba" },  
    { value: "David", label: "David" },  
    { value: "Dolega", label: "Dolega" },  
    { value: "Gualaca", label: "Gualaca" },  
    { value: "Remedios", label: "Remedios" },  
    { value: "Renacimiento", label: "Renacimiento" },  
    { value: "San Félix", label: "San Félix" },  
    { value: "San Lorenzo", label: "San Lorenzo" },  
    { value: "Tierras Altas", label: "Tierras Altas" }
  ],
  "Darién":[
    { value: "Chepigana", label: "Chepigana" },  
    { value: "Pinogana", label: "Pinogana" },  
    { value: "Santa Fe", label: "Santa Fe" }, 
  ],
  Herrera:[
    {value: "Chitré", label: "Chitré"},  
    {value: "Ocú", label: "Ocú"},  
    {value: "Los Pozos", label: "Los Pozos"},  
    {value: "Parita", label: "Parita"},  
    {value: "Pesé", label: "Pesé"},  
    {value: "Santa María", label: "Santa María"},  
    {value: "Las Minas", label: "Las Minas"}
  ],
  "Los Santos":[
    {value: "Las Tablas", label: "Las Tablas"},    
    {value: "Guararé", label: "Guararé"},    
    {value: "Los Santos", label: "Los Santos"},    
    {value: "Pedasí", label: "Pedasí"},    
    {value: "Macaracas", label: "Macaracas"},    
    {value: "Tonosí", label: "Tonosí"},    
    {value: "Pocrí", label: "Pocrí"},    
    {value: "Oria Arriba", label: "Oria Arriba"}
  ],
  "Panamá":[
    {value: "Panamá", label: "Panamá"},  
    {value: "San Miguelito", label: "San Miguelito"},  
    {value: "Chepo", label: "Chepo"},  
    {value: "Chimán", label: "Chimán"},  
    {value: "Taboga", label: "Taboga"},  
    {value: "Balboa", label: "Balboa"},  
    {value: "La Chorrera", label: "La Chorrera"},  
    {value: "Arraiján", label: "Arraiján"},  
    {value: "Capira", label: "Capira"},  
    {value: "Chame", label: "Chame"}
  ],
  Veraguas:[
    {value:"Atalaya", label:"Atalaya"},  
    {value:"Calobre", label:"Calobre"},  
    {value:"Cañazas", label:"Cañazas"},  
    {value:"La Mesa", label:"La Mesa"},  
    {value:"Las Palmas", label:"Las Palmas"},  
    {value:"Montijo", label:"Montijo"},  
    {value:"Río de Jesús", label:"Río de Jesús"},  
    {value:"San Francisco", label:"San Francisco"},  
    {value:"San Lorenzo", label:"San Lorenzo"},  
    {value:"Santa Fe", label:"Santa Fe"},  
    {value:"Santiago", label:"Santiago"},  
    {value:"Soná", label:"Soná"}
  ],
  "Panamá Oeste":[
    {value: "Arraiján", label: "Arraiján"},  
    {value: "Capira", label: "Capira"},  
    {value: "Chame", label: "Chame"},  
    {value: "La Chorrera", label: "La Chorrera"},  
    {value: "San Carlos", label: "San Carlos"},  
    {value: "San Miguelito", label: "San Miguelito"}
  ]

}

