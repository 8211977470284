import { Box, Typography, useTheme } from "@mui/material"
import React from 'react'
import FatalErrorImg from '../../../src/assets/500.png'
import { tokens } from "../../theme";

const FatalError = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return(
    <Box p={5}>
      <Box display="flex" alignItems="center" flexDirection="column" mt="20px">
      <Typography 
        variant="h2" 
        color={colors.grey[100]}
        fontWeight="bold"
        sx={{ mb: "40px"}}
      >
        Error: 500 Unexpected Error
      </Typography>
      <img src={FatalErrorImg} alt="500 Unexpected Error" className="Error_Image" />
      </Box>
    </Box>
  )
}


export default FatalError