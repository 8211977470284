import React from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material';
import { tokens } from '../../theme';

function Footer() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 0,
        width: "100%",
        bgcolor: colors.primary[400],
        textAlign: "center",
        p: 0.2,
      }}
    >
      <p><a href="https://aquacorp.com/" rel="noreferrer" target="_blank">© Grupo Aquacorp, 2023.</a></p>
    </Box>
  );
}

export default Footer;