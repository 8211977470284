import { useState } from "react";
import { ProSidebar, Menu, MenuItem} from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { Link } from "react-router-dom";
import 'react-pro-sidebar/dist/css/styles.css';
import { tokens } from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import PoolOutlinedIcon from "@mui/icons-material/PoolOutlined";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import GradingIcon from '@mui/icons-material/Grading';
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import useAuth from "../../auth/useAuth";
import { useLocation } from "react-router-dom";


const Item = ({title, to, icon, selected, setSelected}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return(
    <MenuItem 
      active={selected === title} 
      style={{ color: colors.grey[100]}} 
      onClick={()=> setSelected(title)} 
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  )
}

const Sidebar = () => {
  const location = useLocation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode)
  const [isCollapsed, setIsCollapsed] = useState(false);
  //console.log(location.pathname)
  const [selected, setSelected] = useState(location.pathname.replace('/',''));
  const auth = useAuth();

  if(auth.globals.auth)
  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#1b4792 !important",
        },
        "& .pro-menu-item.active": {
          color: "#2c75b6 !important",
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square" >
          {/*LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon/> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
            >
              {!isCollapsed && (
                <Box 
                  display = "flex" 
                  justifyContent="space-between"
                  alignItems="center"
                  ml="20px"
                  mb="40px"
                >
                  <Typography variant="h4" color={colors.grey[100]}>
                    ADMINISTRACIÓN
                  </Typography>
                  <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                    <MenuOutlinedIcon/>
                  </IconButton>
                </Box>
              )}
          </MenuItem>

          {/**USER */}
          {!isCollapsed && (
            <Box mb="50px">
              <Box display="flex" justifyContent="center" alignItems="center" mb="25px">
                <img 
                  alt = "profile-user"
                  width = "90%"
                  height = "90%"
                  src = {`../../assets/user.png`}
                  style = {{ cursor: "pointer"}}
                />
              </Box>
              <Box textAlign="center">
                <Typography 
                    variant="h3" 
                    color={colors.grey[100]} 
                    fontWeight="bold" 
                    sx={{ m:"10px 0 0 0"}}
                  >
                    {auth.globals.username}
                  </Typography>
                  <Typography
                    variant="h5" 
                    color={colors.greenAccent[500]} 
                  >
                    {/*auth.globals.type*/}
                    Administrador
                  </Typography>
              </Box>
            </Box>
          )}
          {/**Menu items */}
          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
            <Item
              title="Dashboard"
              to="/Dashboard"
              icon={<HomeOutlinedIcon/>}
              selected = {selected}
              setSelected = {setSelected}
            />
            {!isCollapsed && (
              <Typography
              variant="h6"
              color={colors.grey[300]}
              sx = {{ m: "15px 0 5px 20px"}}
            >
              Administrar
            </Typography>
            )}
            <Item
              title="Clientes"
              to="/Clientes"
              icon={<PeopleOutlinedIcon/>}
              selected = {selected}
              setSelected = {setSelected}
            />
            <Item
              title="Colaboradores"
              to="/Colaboradores"
              icon={<BadgeOutlinedIcon/>}
              selected = {selected}
              setSelected = {setSelected}
            />
            {!isCollapsed && (
              <Typography
              variant="h6"
              color={colors.grey[300]}
              sx = {{ m: "15px 0 5px 20px"}}
              >
              Reportes
            </Typography>
            )}
            <Item
              title="Piscinas"
              to="/Piscinas"
              icon={<PoolOutlinedIcon/>}
              selected = {selected}
              setSelected = {setSelected}
            />
            <Item
              title="Revisiones"
              to="/Revisiones"
              icon={<GradingIcon/>}
              selected = {selected}
              setSelected = {setSelected}
            />
            <Item
              title="Aplicaciones"
              to="/Aplicaciones"
              icon={<AssignmentTurnedInIcon/>}
              selected = {selected}
              setSelected = {setSelected}
            />
            <Item
              title="Mapa"
              to="/Mapa"
              icon={<MapOutlinedIcon/>}
              selected = {selected}
              setSelected = {setSelected}
            />
          </Box>

        </Menu>
      </ProSidebar>
    </Box>
  );
}

export default Sidebar;