import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

/*const store = configureStore({
  reducer: {
    global: globalReducer,
    [api.reducerPath]: api.reducer,
  },
  middleware: (getDefault) => getDefault().concat(api.middleware),
});
setupListeners(store.dispatch);*/

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      {/*<Provider store={store}>*/}
        <App />
      {/*</Provider>*/}    
  </React.StrictMode>
);

