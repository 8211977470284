import { Box, useTheme } from "@mui/material";
import { useLocation } from "react-router-dom";
import Header from "../../components/Header"
import url from "../../config";
import { useFetch2 } from "../../state/useFetch";
import { tokens } from "../../theme";
import { DataGrid, GridToolbar, esES } from "@mui/x-data-grid";
import useAuth from "../../auth/useAuth";
import Loading from "../global/Loading";
import FatalError from "../global/FatalError";

const Revisiones = () => {

  /*function formatear(vector){
    vector.forEach(function(elemento, index) {
      elemento.createdAt = elemento.createdAt.format("DD/MM/YYYY hh:mm A")
    });
    return vector
  }*/


  const theme = useTheme();
  const colors = tokens(theme.palette.mode)
  const auth = useAuth();
  const token = auth.globals.token

  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const _id = query.get('_id')

  const {data,loading,error} = useFetch2(_id!=null?`${url}/api/reviews/pool/${_id}`:`${url}/api/reviews`,token)

  const columns=[
    {
      field:"_id", 
      headerName: "ID",
      hide: true,
      hideable : false,
    },
    {
      field:"user_email", 
      headerName: "Cliente",
      flex: 1.25, 
      cellClassName: "user_email-column--cell"
    },
    {
      field:"pool_id", 
      headerName: "ID_PISCINA",
      hide: true,
      hideable : false,
    },
    {
      field:"chlorine", 
      headerName: "Cloro", 
      flex: 1, 
      cellClassName: "chlorine-column--cell"
    },
    {
      field:"ph", 
      headerName: "PH",
      flex: 1, 
      cellClassName: "ph-column--cell"
    },
    {
      field:"alkalinity", 
      headerName: "Alcalinidad",
      flex: 1, 
      cellClassName: "alkalinity-column--cell"
    },
    {
      field:"createdAt", 
      headerName: "Fecha de Revisión",
      flex: 1, 
      cellClassName: "createdAt-column--cell",
      type: 'dateTime',
      valueGetter: ({ value }) => value && new Date(value)
    }
  ]


  if(loading)
  return (<Loading/>)

  if(error)
  return(<FatalError/>)

  return (
    <Box m="20px">
      {/*<Box display="flex" justifyContent="space-between" alignItems="center">*/}
        <Header title={_id!=null?"":"REVISIONES"} subtitle="Listado de revisiones"/>
        <Box m="20px 0 0 0" height="67vh" sx={{
          "& .MuiDataGrid-root":{
            border: "none",
          },
          "& .MuiDataGrid-cell":{
            borderBottom: "none"
          },
          "& .user_email-column--cell":{
            color: colors.greenAccent[300]
          },
          "& .MuiDataGrid-columnHeaders":{
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
            color: "white"
          },
          "& .MuiDataGrid-virtualScroller":{
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer":{
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text":{
            color: `${colors.grey[100]} !important`,
          },
          "& .MuiToolbar-gutters":{
            color: "white"
          }

        }}>
          <DataGrid
            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
            rows={data}
            columns={columns}
            getRowId={(row) => row._id}
            components={{Toolbar: GridToolbar}}
          />
        </Box>
    </Box>
  );


}

export default Revisiones;